import './App.css';
import {
  Container,
} from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  HashRouter
} from "react-router-dom"
import NavTop from './view/component/NavTop'
import FooterMain from './view/component/FooterMain'
import ProductList from './view/page/product/productList';
import HomePage from './view/page/home/homePage';
import ProductPage from './view/page/product/productPage';
import { useEffect, useState } from 'react';
import Cart from './view/component/Cart';
import Checkout from './view/page/checkout/checkout';
import Payment from './view/page/checkout/payment';
import VerificationEmail from './view/page/verification/verificationEmail';
import axios from 'axios';
import PaymentSuccess from './view/page/checkout/paymentSuccess';
import Profile from './view/page/customer/profile';
import TermMember from './view/component/TermMember';
import SignupGoogle from './view/page/customer/signupGoogle';
import PaymentCard from './view/page/checkout/paymentCard';
import NotificationPage from './view/page/customer/notificationPage';
import ResetPassword from './view/page/customer/resetPassword';
import ResetPasswordForm from './view/page/customer/resetPasswordForm';
import ContactPage from './view/page/contact/contactPage';
import OrderExpirePage from './view/page/checkout/orderExpirePage';
import Maintenance from './view/page/home/maintenance';
import { GlobalData } from './view/component/GlobalData';
import Cookies from 'js-cookie';
import SummaryCart from './view/component/SummaryCart';
import { Toast } from "./view/component/Toast";
import { Helmet } from 'react-helmet';
import { PaymentSnap } from './view/page/checkout/paymentSnap';
import FloatingObject from './view/component/FloatingObject';
import CarouselBanner from './view/component/CarouselBanner';
import { DiscountedProductPage } from './view/page/product/discountedProductPage';
import SizeGuidePage from './view/page/home/sizeGuidePage';
import FlashsaleProductPage from './view/page/product/flashsaleProductPage';
import { Termofuse } from './view/page/term/termofuse';
import { ReturnPolicy } from './view/page/term/returnPolicy';
import { RaffleConfirmPage } from './view/page/raffle/raffleConfirmPage';
import { RafflePage } from './view/page/raffle/rafflePage';

const App = () => {
  // const [cart, setCart] = useState((localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')) : [])
  const cartStructure = {
    customer: {
      address: null
    },
    products: [],
    selected_voucher: [],
    summary: {
      totalItem: 0,
      totalPrice: 0,
      total_order: 0,
      voucher: 0,
      voucher_ongkir: 0,
      kurir: null,
      service: null,
      service_cost: 0,
      total_weight: 0,
      order_id: null,
      id_cart: null,
      date: null,
      asuransi:0,
    }
  }

  const { ...structure } = cartStructure
  const [cart, setCart] = useState(structure)
  const [notif, setNotif] = useState([])
  const [maintenance, setMaintenance] = useState('0')
  const token = (localStorage.getItem('loginData')) ? JSON.parse(localStorage.getItem('loginData')).token : null

  global.IDR = new Intl.NumberFormat('IN', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
    currencyDisplay:'symbol'
  });

  global.Point = new Intl.NumberFormat('IN', {
    minimumFractionDigits: 0,
  });

  global.SRV = "https://hoopsindonesia.co.id/"
  // global.SRV = "http://localhost"

  const pickedProduct = (productCartData) => {
    if (cart.products.length > 0) {
      if (!cart.products.some(i => i.selected_size === productCartData.selected_size && i.selected_color.id_color === productCartData.selected_color.id_color)) {
        setCart({ ...cart, products: [...cart.products, productCartData] })
      } else {
        alert("The item you selected is already in the basket")
      }
    } else {
      setCart(cart => ({
        ...cart,
        products: [...cart.products, productCartData]
      }))
    }
  }

  const handleChange = (i, d) => { 
    const arr = cart.products
    arr[i].qty += d

    if (d === 1) {
      arr[i]['product.total_weight'] += arr[i].product_dimension.weight
    } else {
      arr[i]['product.total_weight'] -= arr[i].product_dimension.weight
    }
    if (arr[i].qty === 0) arr[i].qty = 1
    setCart({ ...cart, products: arr })
  }

  const updateNotif = (idCustomer) => {

    axios({
      method: "GET",
      url: `${global.SRV}api_hoops/controler/notification.php/?index=1&id_customer=${idCustomer}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': token
      },
    })
      .then(response => {
        if (response.data.state === 200) {
          setNotif(response.data.data)
        } else {
          setNotif('!')
        }
      })
  }



  useEffect(() => {
    const cartProduct = async (idCustomer) => {
      const param = new URLSearchParams()
      param.append('id_customer', idCustomer)

      await axios({
        method: "POST",
        url: `${global.SRV}api_hoops/controler/cart.php/?index=5`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'authorization': token
        },
        data: param
      })
        .then((res) => {
          if (res.data.state == 200) {
            setCart(res.data.data)
          }
        })
        .catch((error) => console.log(error.response.state))
    }

    if (JSON.parse(localStorage.getItem('loginData')) !== null && maintenance == '0') {
      cartProduct(JSON.parse(localStorage.getItem('loginData')).id)
      updateNotif(JSON.parse(localStorage.getItem('loginData')).id)
    }

  }, [token])

  useEffect(() => {
    const getMaintState = async () => {
      const param = new URLSearchParams()
      param.append('name', 'maintenance')

      await axios({
        method: "POST",
        url: `${global.SRV}api_hoops/controler/config.php/?index=2`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'authorization': token
        },
        data: param
      })
        .then(res => {
          setMaintenance(res.data)
        })
    }

    getMaintState()
  }, [token])


useEffect(() => {
    if (cart.hasOwnProperty('is_ordered')){
      if (cart.is_ordered == '1'){ 
        Toast.fire({
          icon: 'warning',
          title: 'You have pending order, click cart icon to continue order',
          position:'bottom-right'
        })
      }
    }
  },[])

  return (
    <>
    <Helmet>
      {/* Kode Pixel Facebook */}
      <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '737321260174777');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=737321260174777&ev=PageView&noscript=1" />`}
        </noscript>

    </Helmet>
    
    <GlobalData>
      <FloatingObject/>
      <Router>
        {maintenance == '0' ?
          <div className="App">
            <NavTop cart={cart} notif={notif} updateNotif={updateNotif} setCart={setCart} />
            <Routes>
              <Route path='/' element={<HomePage/>} />
              <Route path='/product_list/:categoryId' element={<ProductList />}/>
              <Route path='/product/:productId' element={<ProductPage pickedProduct={pickedProduct} />}/>
               
              <Route path='/cart' element={<Cart cart={cart} setCart={setCart} handleChange={handleChange} />} />
              <Route path='/cart/checkout' element={<Checkout cart={cart} setCart={setCart} updateNotif={updateNotif} />} />
              <Route path='/verification/verificationEmail' element={<VerificationEmail />} />
              <Route path='/cart/checkout/payment' element={<Payment cart={cart} setCart={setCart} updateNotif={updateNotif} />} />
              <Route path='/cart/checkout/payment_success' element={<PaymentSuccess cart={cart} setCart={setCart} updateNotif={updateNotif} />} />
              <Route path='/customer/profile' element={<Profile cart={cart} updateNotif={updateNotif} />} />
              <Route path='/term/membership' element={<TermMember />} />
              <Route path='/customer/signup' element={<SignupGoogle />} />
              <Route path='/checkout/paymentCard' element={<PaymentCard cart={cart} setCart={setCart} />} />
              <Route path='/customer/notificationPage' element={<NotificationPage />} />
              <Route path='/customer/resetPassword' element={<ResetPassword />} />
              <Route path='/customer/resetPasswordForm' element={<ResetPasswordForm />} />
              <Route path='/contact/contactPage' element={<ContactPage />} />
              <Route path='/checkout/orderExpirePage' element={<OrderExpirePage />} />
              <Route path='/cart/checkout/paymentSnap' element={<PaymentSnap cart={cart} setCart={setCart} updateNotif={updateNotif}/>} />
              <Route path='/product/discountedProductPage' element={<DiscountedProductPage />} />
              <Route path='/home/sizeGuide' element={<SizeGuidePage />} />
              <Route path='/product/flashsaleProductPage' element={<FlashsaleProductPage />} />
              <Route path='/term/termofuse' element={<Termofuse />} />
              <Route path='/term/returnPolicy' element={<ReturnPolicy />} />
              <Route path='/raffle/rafflePage' element={<RafflePage customer={cart.customer} idCart={cart.summary.id_cart}/>} />
              <Route path='/raffle/winnerConfirmation/:idRaffle/:idRegistration' element={<RaffleConfirmPage cart={cart} setCart={setCart}/>} />
            </Routes>
            <FooterMain />
          </div> : <Maintenance />
        }
      </Router>
    </GlobalData>
    </>
  );
}

export default App;
