import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { Button, Dimmer, Divider, Form, Icon, Label, Loader, Popup } from "semantic-ui-react";
import { useMyGlobal } from "../../component/GlobalData";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../component/Toast";

export const RafflePage = ({ customer, idCart }) => {
    const idCustomer = customer.id_customer
    const navigate = useNavigate()
    const [sortDataSize, setSortDataSize] = useState([])
    const [sortDataSizeGroup, setSortDataSizeGroup] = useState([])
    const [dataPage, setDataPage] = useState([])
    const [selectedSize, setSelectedSize] = useState([])
    const [defaultImage, setDefaultImage] = useState()
    const [productImage, setProductImage] = useState([])
    const [productDiscount, setProductDiscount] = useState()
    const [discountTotal, setDiscountTotal] = useState()
    const [sizeQty, setSizeQty] = useState('')
    const { token, windowWidth } = useMyGlobal()
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [address, setAddress] = useState([])
    const [selectedAddr, setSelectedAddr] = useState(null)
    const [loadProduct, setLoadProduct] = useState(false)
    const [dataRaffle, setDataRaffle] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [loadSubmit, setLoadSubmit] = useState(false)

    const setProductDetail = (dataProduct) => {
        dataProduct.forEach((item, idx) => {
            // setDefaultImage(defaultImage => [...defaultImage, item.image.length > 0 ? item.image.filter(i => i.is_default == 1).map(i => i) : 'https://via.placeholder.com/500x400.png'])
            setProductImage(item.image.map(i => i.image))
        })

        // setdataProductDiscount((dataProduct.discount) ? dataProduct.discount.discount_value : 0)
        setDiscountTotal((dataProduct.discount) ? dataProduct.discount.discount_total : 0)

        const handleProductDsc = () => {
            if (dataProduct.isDiscounted === '1' && dataProduct.fs_on) {
                setProductDiscount(dataProduct.fs_discount)
            } else if (dataProduct.isDiscounted === '1' && !dataProduct.fs_on) {
                setProductDiscount(dataProduct.discount.discount_value)
            } else if (dataProduct.isDiscounted === '0' && dataProduct.fs_on) {
                setProductDiscount(dataProduct.fs_discount)
            } else if (dataProduct.isDiscounted === '0' && !dataProduct.fs_on) {
                setProductDiscount(0)
            }
        }

        const handleDiscountTotal = () => {
            if (dataProduct.isDiscounted === '1' && dataProduct.fs_on) {
                setDiscountTotal(dataProduct.fs_discount_value)
            } else if (dataProduct.isDiscounted === '1' && !dataProduct.fs_on) {
                setDiscountTotal(dataProduct.discount.discount_total)
            } else if (dataProduct.isDiscounted === '0' && dataProduct.fs_on) {
                setDiscountTotal(dataProduct.fs_discount_value)
            } else if (dataProduct.isDiscounted === '0' && !dataProduct.fs_on) {
                setDiscountTotal(0)
            }
        }

        handleProductDsc()
        handleDiscountTotal()
    }

    const handleClickSize = (data, id_product, size) => {
        const indexToUpdate = selectedSize.findIndex(item => item.id_product === id_product);

        // Jika index ditemukan, update nilai size
        if (indexToUpdate !== -1) {
            // Salin selectedSize ke variabel baru agar tidak memodifikasi state langsung
            const updatedSelectedSize = [...selectedSize];

            // Update nilai size pada elemen yang ditemukan
            updatedSelectedSize[indexToUpdate] = {
                ...updatedSelectedSize[indexToUpdate],
                size: size
            }

            // Set state dengan nilai yang baru
            setSelectedSize(updatedSelectedSize);
        } else {
            // Jika id_product tidak ditemukan dalam selectedSize, tambahkan size baru ke dalam array selectedSize
            setSelectedSize([...selectedSize, size]);
        }

        if (dataPage.qty > 0) {
            setSizeQty(dataPage.barcode.filter(o => o.size == data.size)[0].qty)
        } else {
            setSizeQty(0)
        }
    }

    const ImageChild = ({ src }) => {
        return src.map((item, i) => (
            <Col md={3} sm={3} xs={3} lg={3} xl={3} key={i} className='mt-3'>
                <Image src={item.image}
                    fluid
                    rounded
                    style={{ cursor: 'pointer' }}
                    onClick={() => setDefaultImage(item)}
                />
            </Col>
        ))
    }

    const ImageMain = ({ src }) => {
        return (
            <Col sm={12} md={12} lg={12} xl={12}>
                <Image src={src} fluid rounded />
            </Col>
        )
    }

    const handleClickProduct = (data) => {
        setSelectedProduct(data)
    }

    const handleClickAddress = (address) => {
        setSelectedAddr(address)
    }

    const handleClickSubmit = () => {
        let submitedData = {}
        if (selectedProduct) {
            setLoadSubmit(true)
            submitedData = {
                id_customer: customer.id_customer,
                id_member: customer.id_member,
                product: selectedProduct,
                size: selectedSize?.find(s => s.id_product === selectedProduct.id_product).size,
                id_cart: idCart,
                id_raffle: dataRaffle.raffle.id_raffle,
                barcode: selectedProduct.barcode.find(b => b.size === selectedSize?.find(s => s.id_product === selectedProduct.id_product).size).barcode,
                phone: phoneNumber,
            }

            axios({
                method: "POST",
                url: `https://hoopsindonesia.co.id/api_hoops/controler/event.php?index=9`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                },
                data: submitedData
            }).
                then(res => {
                    if (res.data.state === 200) {
                        setLoadSubmit(false)
                        Toast.fire({
                            title: "Regitration Success",
                            icon: 'success',
                            text: 'Thank you for registering, I wish you luck',
                            position: 'center',
                        })
                    } else if (res.data.state === 300) {
                        setLoadSubmit(false)
                        Toast.fire({
                            title: "Regitration Denied",
                            icon: 'warning',
                            text: 'You have successfully registered previously, each customer can only register once',
                            position: 'center',
                        })
                    } else if (res.data.state === 500) {
                        setLoadSubmit(false)
                        Toast.fire({
                            title: "Regitration Failed",
                            icon: 'error',
                            text: 'Maybe our system has a little problem, try another time.',
                            position: 'center',
                        })
                    }
                })

        } else {
            Toast.fire({
                icon: 'warning',
                title: "No products selected",
                text: "If you haven't selected a product, select one of the available products",
                position: 'center'
            })
        }

    }

    useEffect(() => {

        const getProductId = async () => {
            await axios({
                method: "GET",
                url: `https://hoopsindonesia.co.id/api_hoops/controler/event.php?index=8`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                },
            })
                .then(res => {
                    if (res.data.state === 200) {
                        setDataRaffle(res.data.data)
                        getProductData(res.data.data.products)
                    }
                })
        }

        const getProductData = async (products) => {
            setLoadProduct(true)
            await axios({
                method: "POST",
                url: `https://hoopsdevteam.com/api_web/controler/product.php?index=23`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': token
                },
                data: products
            })
                .then(res => {
                    let data = res.data.data
                    const groupedData = []
                    data.map((item, i) => {
                        const numericSizes = item.barcode.filter(item => !isNaN(parseFloat(item.size)))
                        const nonNumericSizes = item.barcode.filter(item => isNaN(parseFloat(item.size)))
                        let exist = selectedSize.some(s => s.id_product)
                        if (!exist) {
                            setSelectedSize(selectedSize => [...selectedSize, {
                                id_product: item.id_product,
                                size: null
                            }])
                        }

                        if (numericSizes.length > 0) {
                            const size = item.barcode.sort((a, b) => parseFloat(a.size) - parseFloat(b.size))
                            if (size.length > 4) {
                                for (let i = 0; i < size.length; i += 4) {
                                    groupedData.push(size.slice(i, i + 4))
                                }
                                setSortDataSizeGroup(sortDataSizeGroup => [...sortDataSizeGroup, groupedData])
                            } else {
                                setSortDataSizeGroup(sortDataSizeGroup => [...sortDataSizeGroup, size])
                            }


                            setSortDataSize(sortDataSize => [...sortDataSize, size])
                        } else if (nonNumericSizes.length > 0) {
                            const sortNonNumeric = (a, b) => {
                                const sizeOrder = { "S": 1, "M": 2, "L": 3, "XL": 4, "XXL": 5 };
                                return sizeOrder[a.size] - sizeOrder[b.size];
                            }
                            const size = data.barcode.sort(sortNonNumeric)
                            for (let i = 0; i < size.length; i += 6) {
                                groupedData.push(size.slice(i, i + 6));
                            }

                            setSortDataSizeGroup(groupedData)
                            setSortDataSize(sortDataSize => [...sortDataSize, size])
                        }
                    })

                    setDataPage(data)
                    setProductDetail(data)
                    setLoadProduct(false)
                    // setLogin(JSON.parse(ses.getItem('loginData')))

                    // if (data.color.length == 1) {
                    //     setSelectedColor(data.color[0])
                    // }

                    // if (data.size.length == 1) {
                    //     let size = data.size
                    //     setSelectedSize(data.size[0])
                    //     if (data.qty > 0) {
                    //         setSizeQty(data.barcode.filter(o => o.size == size)[0].qty)
                    //     } else {
                    //         setSizeQty(0)
                    //     }
                    // }

                })
        }

        getProductId()
        window.scrollTo(0, 0)
    }, [])


    // useEffect(() => {
    //     const getAddress = async (idCus) => {
    //         const param = new URLSearchParams()
    //         param.append('id_customer', idCus)
    //         await axios({
    //             method: "POST",
    //             url: `${global.SRV}api_hoops/controler/customer.php/?index=3`,
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded',
    //                 'authorization': token
    //             },
    //             data: param
    //         })
    //             .then(res => {
    //                 if (res.data.state === 200) {
    //                     setAddress(res.data.data)
    //                 }
    //             })
    //     }
    //     // if (customer.id_customer) getAddress(customer.id_customer)
    // }, [customer])


    return (
        <Container style={{ marginTop: '15vh' }}>
            <Row>
                <Col>
                    <h2 className="text-uppercase">Raffle Page</h2>
                    <Divider horizontal>Raffle registration page</Divider>
                </Col>
            </Row>
            <Row>
                {
                    dataRaffle ?
                        customer.id_customer ?
                            <>
                                <Dimmer active={loadProduct} inverted>
                                    <Loader inverted>Loading Product ...</Loader>
                                </Dimmer>
                                <Col xs={12} sm={12} md={12} lg={8} xl={8} className="text-start">
                                    <h3>Raffle Product</h3>

                                    {
                                        dataPage?.map((item, i) => (
                                            <Card key={i} className="mb-4" style={selectedProduct?.id_product === item.id_product ? { borderColor: 'orange', borderWidth: '2px' } : { borderColor: 'lightgray' }}>
                                                <Card.Body>
                                                    <Row className="mb-4">
                                                        <Col xs={12} sm={12} md={12} lg={4} xl={4} className="text-start mb-5">
                                                            <Row >
                                                                {
                                                                    defaultImage && defaultImage.id_product == item.id_product ? <ImageMain src={defaultImage.image} /> :
                                                                        item.image.map((item, i) => {
                                                                            if (item.is_default === '1') {
                                                                                return (
                                                                                    <ImageMain key={i} src={typeof item != 'undefined' ? item.image : 'https://via.placeholder.com/500x400.png'} />
                                                                                )
                                                                            }
                                                                        })
                                                                }
                                                            </Row>
                                                            <Row>
                                                                <ImageChild src={item.image} />
                                                            </Row>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                            <div className="fw-bold mb-2">{item.product_name}</div>
                                                            <div className="mb-2">
                                                                <span className='pe-3'>{Array.isArray(item.category_name) ? item.category_name.length > 1 ? item.category_name.map(o => o + ', ') : item.category_name : dataPage.category_name}</span>
                                                                <span className='me-3'><Label size="small">{item.segment_name}</Label></span>
                                                                <span className='me-3'><Label size="small">{item.id_product}</Label></span>
                                                            </div>
                                                            <div className="mb-2">{item.product_desc}</div>
                                                            <div className='text-start fs-5 fw-semibold mb-2'>{global.IDR.format(item.final_price)}</div>
                                                            <div className='mb-2'>Size Group : {(item.size_group) ? <span className='fw-bold'>{item.size_group}</span> : <span className='text-muted'>not set</span>}</div>
                                                            <label>Selected Size : {item.qty > 0 ? selectedSize[i].size ? <span className='fw-bold'>{selectedSize[i].size}</span> : <span className='text-muted'>not selected</span> : <span className='text-muted'>not available</span>}</label>
                                                            {
                                                                windowWidth < 760 ?
                                                                    sortDataSizeGroup[i].map((sizeGroup, sg) => {
                                                                        return (
                                                                            <Col className='text-start mt-2 mb-3' sx={12} sm={12} md={12} lg={12} >
                                                                                <Button.Group key={sg}>
                                                                                    {
                                                                                        sizeGroup.length > 1 ?
                                                                                            sizeGroup.map((group, g) => {
                                                                                                return (
                                                                                                    <Button
                                                                                                        style={selectedProduct?.id_product === item.id_product ? { display: 'block' } : { display: 'none' }}
                                                                                                        value={group.size}
                                                                                                        onClick={e => handleClickSize(item, item.id_product, group.size)}
                                                                                                        key={g}
                                                                                                        className={(selectedSize == group.size) ? 'active' : ''}
                                                                                                    >
                                                                                                        {group.size}
                                                                                                    </Button>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            sortDataSizeGroup[i].map((sizeGroup, sg) => {
                                                                                                return (
                                                                                                    <Button
                                                                                                        style={selectedProduct?.id_product === item.id_product ? { display: 'block' } : { display: 'none' }}
                                                                                                        value={sizeGroup.size}
                                                                                                        onClick={e => handleClickSize(item, item.id_product, sizeGroup.size)}
                                                                                                        key={sg}
                                                                                                        className={(selectedSize == sizeGroup.size) ? 'active' : ''}
                                                                                                    >
                                                                                                        {sizeGroup.size}
                                                                                                    </Button>
                                                                                                )
                                                                                            })
                                                                                    }
                                                                                </Button.Group>
                                                                            </Col>
                                                                        )
                                                                    })
                                                                    :
                                                                    <Col className='text-start mt-2 mb-3' sx={12} sm={12} md={12} lg={12}>
                                                                        {
                                                                            <Button.Group>
                                                                                {
                                                                                    sortDataSize[i]?.map((size, i) => {
                                                                                        return (
                                                                                            <Button
                                                                                                style={selectedProduct?.id_product === item.id_product ? { display: 'block' } : { display: 'none' }}
                                                                                                value={size.size}
                                                                                                onClick={e => handleClickSize(size, item.id_product, size.size)}
                                                                                                key={i}
                                                                                                className={(selectedSize == item.size) ? 'active' : ''}
                                                                                            >
                                                                                                {size.size}
                                                                                            </Button>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Button.Group>
                                                                        }
                                                                    </Col>
                                                            }
                                                            <Button
                                                                content={selectedProduct?.id_product === item.id_product ? "Selected" : "Pick This Product"}
                                                                icon={selectedProduct?.id_product === item.id_product ? 'check' : 'hand point up outline'}
                                                                color={selectedProduct?.id_product === item.id_product ? 'orange' : 'black'}
                                                                onClick={() => handleClickProduct(item)}
                                                                className="mb-3 mt-3"
                                                            />

                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        ))
                                    }
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={4} xl={4} className="text-start">
                                    <h3>Registration</h3>
                                    <Card>
                                        <Card.Body>
                                            <Form widths="equal" onSubmit={handleClickSubmit}>
                                                <Form.Input type="text" label="Customer Name" value={customer.first_name || customer.last_name ? customer.first_name + ' ' + customer.last_name : ''} icon={customer.gender === 'M' ? 'male' : 'female'} required />
                                                <Form.Input type="email" label="Registered Email" value={customer.email} icon='at' className="mb-3" required />
                                                <Form.Input type="phone" label="Active Phone Number" value={phoneNumber} icon='phone' className="mb-5" onChange={(e) => setPhoneNumber(e.target.value)} required />
                                                {/* <div className="fw-bold mb-2">Select Address</div> */}
                                                {/* {
                                                    address?.map((item, i) => (
                                                        <Card className="mb-3"
                                                            onClick={() => handleClickAddress(item)}
                                                            style={selectedAddr?.id_address === item.id_address ? { borderColor: 'orange', borderWidth: '2px', cursor: 'pointer' } : { borderColor: 'lightgray', cursor: 'pointer' }}
                                                        >
                                                            <Card.Body>
                                                                <Row>
                                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                        <div className="fw-bold">{item.address_label}</div>
                                                                    </Col>
                                                                    {
                                                                        selectedAddr?.id_address === item.id_address ?
                                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-end">
                                                                                <Label content="Selected" icon="check" color="orange" />
                                                                            </Col>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </Row>
                                                                <div>
                                                                    <span className="me-2">{item.recipient_name}</span>
                                                                    <span>({item.celular_number})</span>
                                                                </div>
                                                                <div>{item.address_detail}</div>
                                                                <div>{item.address_detail_dua}</div>
                                                                <div>
                                                                    <span>{item.sub_district}, </span>
                                                                    <span>{item.district}, </span>
                                                                    <span>{item.city}</span>
                                                                </div>
                                                                <div>
                                                                    <span>{item.province}, </span>
                                                                    <span>{item.zip_code}</span>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    ))
                                                } */}
                                                <div className="mb-2 fw-bold fs-5"><Icon name="exclamation circle" color="orange" size="large" /> Information</div>
                                                <ul>
                                                    <li>
                                                        The raffle winner will receive an email notification, which contains a URL or link to redirect to the checkout page of the Hoops Indonesia website
                                                    </li>
                                                    <li>
                                                        Click the link and you will be directed to the checkout page, select the address and courier, then click the select payment button
                                                    </li>
                                                    <li>
                                                        Make payment and wait for your goods to arrive.
                                                    </li>
                                                    <li>
                                                        Good luck...
                                                    </li>
                                                </ul>
                                                <Button
                                                    className="mt-5"
                                                    size="big"
                                                    content="Register Now"
                                                    type="submit"
                                                    fluid color="black"
                                                    icon="arrow alternate circle right outline"
                                                    labelPosition="right"
                                                    loading={loadSubmit}
                                                />
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </>
                            :
                            <div className="justify-content-center mt-4" style={{ minHeight: '12em' }}>
                                <Icon name="exclamation circle" size="big" color="orange" />
                                <div className="fw-bold mt-3">Can't enter this page</div>
                                <div className="mb-3">Please log in first if you have an account or register your account.</div>
                                <div onClick={() => navigate('/')} style={{ color: 'coral', cursor: 'pointer' }}>Bact to homepage</div>
                            </div>
                        :
                        <div className="justify-content-center mt-4" style={{ minHeight: '12em' }}>
                            <Icon name="exclamation circle" size="big" color="orange" />
                            <div className="fw-bold mt-3">There are no raffle events.</div>
                            <div className="mb-3">Maybe the raffle registration hasn't started or even finished.</div>
                            <div onClick={() => navigate('/')} style={{ color: 'coral', cursor: 'pointer' }}>Bact to homepage</div>
                        </div>
                }
            </Row>
        </Container>
    )
}