import axios, { all } from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Form, Icon, Message } from "semantic-ui-react";
import { useMyGlobal } from "../../component/GlobalData";


const ResetPassword = () => {
    const location = useLocation().state
    const [isLoad, setIsLoad] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [email, setEmail] = useState(location.email)
    const [disable, setDisable] = useState(false)
    const [fail, setFail] = useState(false)
    const [allow, setAllow] = useState(false)
    const {token} = useMyGlobal()

    const handleSubmit = () => {
        const param = new URLSearchParams()
        param.append('email', location.email)
        
        if (allow) {
            setIsLoad(true)
            setDisable(true)
            axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/login.php/?index=3`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                },
                data: param
            })
                .then(res => { 
                    if (res.data.state === 200){
                        setIsLoad(false)
                        setIsSuccess(true)
                        setDisable(false)
                    }else {
                        setFail(true)
                        setIsLoad(false)
                        setDisable(false)
                    }
                })
        }else{
            alert("Can't do this at this time... !")
        }
    }
    

    useEffect(() => {
        const checkLastSent = () => {
            axios.get(`${global.SRV}api_hoops/controler/login.php/?index=6&email=${email}`)
            .then(res => {
                if (res.data === false) {
                    setAllow(res.data)
                    setDisable(true)
                }else{
                    setAllow(res.data)
                    setDisable(false)
                }
            })
        }

        checkLastSent()
    },[])


    return (
        <>
            <section style={{ marginTop: "15rem", minHeight: "100vh", height: "100%" }}>
                <Container>
                    <Row className="d-flex justify-content-center mb-5">
                        <Col md={8} lg={6} xl={4} className="text-start">
                        {
                            fail &&
                            <Message
                                icon='exclamation circle'
                                header='Failed, to sent email'
                                content="We can't send emails, maybe there is an error on the server or the email address you entered is not correct."
                            />
                        }
                        {
                            !allow && 
                            <Message
                                icon='exclamation circle'
                                header='You just request to reset.'
                                content="Wait for an email to be sent to your email address, you can request again after 30 minutes from the last time you requested."
                            />
                        }
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center mb-3">
                        <Col xs={12} sm={10} md={6} lg={4} xl={4}>
                        {!isSuccess ?
                        <>
                            <p className="fs-5 text-start fw-bold">Enter your email and we'll send you a link to reset your password.</p>
                            <Form className="text-start" onSubmit={handleSubmit}>
                                <Form.Input
                                    label="Email Address"
                                    type="email"
                                    size="large"
                                    placeholder="Ex : abc@def.com"
                                    defaultValue={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Form.Button content="Send link to email" size="large" fluid loading={isLoad} disabled={disable}/>
                            </Form>
                        </>
                        :
                        <>
                            <Stack direction="vertical" gap={3}>
                            <Icon name="check circle outline" className="text-start fs-3"/>
                            <div className="fs-5 text-start fw-bold">Email sent</div> 
                            <div className="text-start">Check your email and open the link we sent to continue.</div>
                            </Stack>
                        </>
                        }
                        
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ResetPassword