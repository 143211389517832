import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Divider } from "semantic-ui-react";
import { useMyGlobal } from "../../component/GlobalData";

export const ReturnPolicy = () => {
    const {windowWidth, isPortrait} = useMyGlobal()

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    return (
        <Container style={windowWidth > 1000 && !isPortrait ? { marginTop: '8rem' } : { marginTop: '6rem'}}>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} sl={12} >
                    <h2 className="text-uppercase">Return Policy</h2>
                    <Divider horizontal style={windowWidth < 1000 && !isPortrait ? { fontSize: '0.6em' } : {}}>These are the Return Policy of the Hoops Indonesia online store</Divider>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex justify-items-start mt-3">
                    <div className="text-start" style={windowWidth < 1000 && !isPortrait ? { fontSize: '0.9em' } : {}}>
                        <p>Date of Revision : April 2021</p>
                        <p>HOOPS INDONESIA return policy is subjected to the provision of these terms which include but not limited to;</p>
                        <ol>
                            <li>HOOPS INDONESIA will only do return for products purchased at our retail store or online store.</li>
                            <li>Products may only be return within 7-day. The 7-day period commences on the day of receipt of the ordered product. The timely dispatch of the products or the request to take back the products will suffice for the compliance with the right of return deadline.</li>
                            <li>HOOPS INDONESIA will only accept returns if there are faults or defect in the product, or if there is an error made by HOOPS INDONESIA</li>
                            <li>The right to return product only applies if the products are unused and complete in their original condition including their original packaging insofar as reasonably possible.</li>
                            <li>Custom products are final and are not eligible for returns, exchanges, changes, or alterations.</li>
                        </ol>
                        <p>In the event of a valid return in accordance with these Return Policy, HOOPS INDONESIA will exchange the purchased product received from you within fourteen days of receipt of the product.</p>
                        <p>DISCLAIMER</p>
                        <p>If a product is returned that HOOPS INDONESIA believes has been damaged because of an act or omission for which you are to blame, or which is otherwise for your expense and risk, HOOPS INDONESIA will be entitled to deduct the decrease in value of the product as a result of this damage from the amount to be repaid to you or outright decline your return request. You can avoid the obligation to compensate the decrease in value of a product caused by use by not using the product and by refraining (as far as reasonably possible) from any actions that could negatively affect its value. </p>
                        <p>If a product is returned that HOOPS INDONESIA believes has been swapped with a counterfeit product, HOOPS INDONESIA have the right to conduct a formal investigation and legal action through Indonesia Justice System.</p>
                        <p>The above right of return applies in addition to, and does not affect, your rights under the consumer guarantees which apply under the Indonesia Consumer Law.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}