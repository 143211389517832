import axios from "axios";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Stack, Image, Modal, Spinner, InputGroup, Alert, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Accordion, Button, Divider, Icon, Label, List, Menu, Segment, Pagination, Form as Frm, Message, Popup } from "semantic-ui-react";
import ProductCard from "../../component/ProductCard";
import TermMember from "../../component/TermMember";
import AsyncSelect from 'react-select/async';
import AddressForm from "../../component/AddressForm";
import { useMyGlobal } from "../../component/GlobalData";
import LZString from 'lz-string';
import { Toast } from "../../component/Toast";
import noDataImg from "../../assets/no_data.png"
import dataDestinasi from '../../assets/data.json'
// import Pagination from "../../component/Pagination";

const Profile = ({ cart, updateNotif }) => {
    const showMenu = useLocation().state
    const navigate = useNavigate()
    const idCustomer = (localStorage.getItem('loginData')) ? JSON.parse(localStorage.getItem('loginData')).id : null
    const [dataTrx, setDataTrx] = useState([])
    const [profile, setProfile] = useState([])
    const formatLongDate = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    const [activeMenu, setActiveMenu] = useState()
    const [orderDetail, setOrderDetail] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [activeIndex, setActiveIndex] = useState(false)
    const [trackJneData, setTrackJneData] = useState(null)
    const [productData, setProductData] = useState([])
    const [history, setHistory] = useState(null)
    const [awbNumber, setAwbNumber] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [trxPerPage] = useState(3)
    const [joinForm, setJoinForm] = useState(false)
    const [termMember, setTermMember] = useState(false)
    const [allCity, setAllCity] = useState([])
    const [optionCity, setOptionCity] = useState([])
    const [zipCode, setZipCode] = useState(null)
    const [isLoadOption, setIsLoadOption] = useState(false)
    const [member, setMember] = useState(null)
    const [awbResponse, setAwbResponse] = useState('')


    const [phone, setPhone] = useState('')
    const [iden, setIden] = useState(null)
    const [idenNumber, setIdenNumber] = useState('')
    const [checkCorrect, setCheckCorrect] = useState(false)
    const [checkTerm, setCheckTerm] = useState(false)
    const [city, setCity] = useState('')
    const [addr, setAddr] = useState('')
    const [allowReg, setAllowReg] = useState('dissalow')
    const [firstPoint, setFirstPoint] = useState(0)
    const [alertJoin, setAlertJoin] = useState(false)
    const [imageProf, setImageProf] = useState('https://via.placeholder.com/300x300.png')
    const [openTrk, setOpenTrk] = useState(false)
    const [addressList, setAddressList] = useState([])
    const [modalAddress, setModalAddress] = useState(false)
    const [selectedAddressEdit, setSelectedAddressEdit] = useState(null)
    const [modalAddressEdit, setModalAddressEdit] = useState(false)
    const [modalAddressList, setModalAddressList] = useState(false)
    const [userAddress, setUserAddress] = useState(null)
    // const [orderAddress, setOrderAddress] = useState(null)
    const [hideWarn, setHideWarn] = useState(true)
    const [addressState, setAddressState] = useState({})
    const { token, windowWidth } = useMyGlobal()
    const [wishData, setWishData] = useState([])

    let dateOption = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

    const indexOfLastTrx = currentPage * trxPerPage;
    const indexOfFirstTrx = indexOfLastTrx - trxPerPage;

    const currentList = dataTrx.slice(indexOfFirstTrx, indexOfLastTrx)

    const handlMenuClick = (name) => {
        setActiveMenu(name)
    }

    const closeAddressForm = () => (setModalAddress(false))
    const showAddressForm = () => (setModalAddress(true))

    const handleClickAccord = (e) => {
        setActiveIndex(!activeIndex)
    }

    const handleChangeIndeNumber = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === null || re.test(e.target.value)) {
            setIdenNumber(e.target.value)
        } else {
            e.target.value = ''
        }

    }

    const handleChangePhoneNumber = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === null || re.test(e.target.value)) {
            setPhone(e.target.value)
        } else {
            e.target.value = ''
        }

    }

    const closeAddressFormEdit = () => (setModalAddressEdit(false))
    const showAddressFormEdit = () => (setModalAddressEdit(true))
    const closeAddressList = () => (setModalAddressList(false))

    const showOrderDetail = () => (setOrderDetail(true))

    const hideOrderDetail = () => (setOrderDetail(false))

    const hideJoinForm = () => (setJoinForm(false))

    const showAlertJoin = () => (setAlertJoin(true))

    const closeAlertJoin = () => (setAlertJoin(false))

    const hideTermMember = () => {
        if (allowReg === "allowed") {
            setTermMember(false)
            setJoinForm(true)
        } else {
            setTermMember(false)
        }
    }

    const trackingJNE = async (awb) => {
        const param = new URLSearchParams()

        param.append('awb', awb)

        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/courier.php/?index=4`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
            data: param
        })
            .then(res => {
                setOpenTrk(true)
                setAwbResponse(res.data.error)
                if (res.data.status != false && typeof res.data == 'object') {
                    setTrackJneData(res.data)
                    setHistory(res.data.history.sort((a, b) => a.date - b.date).reverse())
                } else if (typeof res.data !== 'object') {
                    Toast.fire({
                        icon: "warning",
                        text: "Resi number can't be tracked yet, maybe there is an error on courier response",
                        position: "center"
                    })
                }
            })
    }

    const TrxAction = ({ state, item }) => {
        switch (state) {
            case "PAYMENT PENDING":
                return (
                    <Col md={12} className="d-flex justify-content-start align-self-end">
                        <Button
                            content="Continue to Payment"
                            basic
                            size="small"
                            onClick={() => navigate('/cart/checkout/paymentSnap')}
                        />
                    </Col>
                )
            case "SETTLEMENT":
                return (
                    <Col md={12} className="d-flex justify-content-start align-self-end">
                        <Button
                            content="View Order Detail"
                            basic
                            color="orange"
                            size="small"
                            onClick={() => {
                                showOrderDetail()
                                setSelectedOrder(item)
                            }}
                        />
                    </Col>
                )
            case "CAPTURE":
                return (
                    <Col md={12} className="d-flex justify-content-start align-self-end">
                        <Button
                            content="View Order Detail"
                            basic
                            size="small"
                            onClick={() => {
                                showOrderDetail()
                                setSelectedOrder(item)
                            }}
                        />
                    </Col>
                )
            case "IN DELIVERY":
                return (
                    <Col md={12} className="d-flex justify-content-start align-self-end">
                        <Button
                            content="View Order Detail"
                            basic
                            color="orange"
                            size="small"
                            onClick={() => {
                                showOrderDetail()
                                setSelectedOrder(item)
                                setAwbNumber(item.no_resi)
                            }}
                        />
                    </Col>
                )
            case "ARRIVE AT DESTINATION":
                return (
                    <Col md={12} className="d-flex justify-content-start align-self-end">
                        <Button
                            content="View Order Detail"
                            basic
                            size="small"
                            onClick={() => {
                                showOrderDetail()
                                setSelectedOrder(item)
                                setAwbNumber(item.no_resi)
                            }}
                        />
                    </Col>
                )
            case "WAITING PAYMENT":
                return (
                    <Col md={12} className="d-flex justify-content-start align-self-end">
                        <Button
                            content="Continue to Order"
                            basic
                            size="small"
                            onClick={() => navigate("/cart/checkout")}
                        />
                    </Col>
                )
            default: return (<></>)
        }
    }

    const SpinerBorder = () => {
        return (<><Divider horizontal>Or wait for it to load</Divider><Spinner animation="border" /></>);
    }


    const handleChangePage = (e, { activePage }) => (setCurrentPage(activePage))

    const calcCoret = (data) => {
        if (data.fs_on === true) {
            return data.price
        } else if (data.isDiscounted == '1') {
            return data.price
        }
    }

    const Transaction = ({ data }) => {
        if (data.length === 0) {
            return (
                <Segment>
                    <h3><Icon name="shopping basket" className="me-2" />Transaction</h3>
                    <div>You haven't made any transactions, let's buy some products now</div>
                    <div className="text-center"><SpinerBorder /></div>
                </Segment>
            )
        } else {
            return (
                <Segment>
                    <h3><Icon name="shopping basket" className="me-2" />Transaction</h3>
                    {
                        data.map((item, idx) => {
                            return (
                                <div className={item.order_state.toLowerCase() == 'settlement' || item.order_state.toLowerCase() == 'in delivery' ? 'callout-active' : 'callout'} key={idx}>
                                    <Row>
                                        <Col xs={12} sm={12} md={9}>
                                            <Row  className="mb-3">
                                                <Col xs={12} sm={12} md={12} className="mb-2">
                                                    <Stack direction="horizontal" gap={2}>
                                                    <div><Icon name="shop" /></div>
                                                    <div className="fw-bold">{item.id_order}</div>
                                                    <div>{new Date(item.order_date).toLocaleDateString('en-US', formatLongDate)}</div>
                                                    </Stack>
                                                </Col>
                                                {/* <Col xs={12} sm={12} md={4} className="text-end">
                                                    <Popup
                                                        trigger={<Label size="mini" color={item.order_state.toLowerCase() == 'settlement' || item.order_state.toLowerCase() == 'in delivery' ? 'orange' : 'grey'}>{item.order_state}</Label>}
                                                        content="Your order status"
                                                    />
                                                </Col> */}
                                            </Row>
                                            {
                                                item.product_details.map((det, i) => {
                                                    return (
                                                        <Stack direction="horizontal" gap={3} key={i} className="mb-3">
                                                            <Image
                                                                className="border"
                                                                src={det.image_order ? det.image_order : "https://via.placeholder.com/500x400.png"}
                                                                width={75}
                                                                rounded
                                                            />
                                                            <Stack direction="vertical" gap={0}>
                                                                {/* <div className="fw-bold">{det.product_name || det.name_order + ' (Unavailable)'}</div> */}
                                                                <div>{det.id_product}</div>
                                                                <div className="fw-bold" style={{color:"#ff5733", cursor:'pointer'}} onClick={() => navigate(`/product/${det.id_product}`)}>{det.product_name || det.name_order}</div>
                                                                <div>
                                                                    <span className="me-2">{global.IDR.format(det.total_price_order)}</span>
                                                                    {/* <span className="me-4 text-muted"><s>{calcCoret(det)}</s></span> */}
                                                                    <div>
                                                                        <span className="me-4">Size : {det.size_order}</span>
                                                                        <span className="me-4">Qty : {det.qty_order}</span>
                                                                    </div>
                                                                </div>
                                                            </Stack>
                                                        </Stack>
                                                    )
                                                })
                                            }
                                            <div>
                                                {
                                                    item.lottery_number &&
                                                    <Popup
                                                        trigger={<Label color="orange"><Icon name="sticky note outline" />{item.lottery_number}</Label>}
                                                        content="You have received a lottery code from this transaction"
                                                    />

                                                }
                                            </div>
                                        </Col>
                                        <Col md={3} className="align-self-center">
                                            <Row style={{ height: '15vh' }}>
                                                <Col md={12} className="">
                                                    <Popup
                                                        trigger={<Label size="mini" color={item.order_state.toLowerCase() == 'settlement' || item.order_state.toLowerCase() == 'in delivery' ? 'orange' : 'grey'}>{item.order_state}</Label>}
                                                        content="Your order status"
                                                    />
                                                </Col>
                                                <Col md={12} className="justify-content-start align-self-center">
                                                    <div className="me-3">Total Order</div>
                                                    <div className="fw-bold">{global.IDR.format(item.total_payment)}</div>
                                                </Col>
                                                <TrxAction state={item.order_state} item={item} />
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                        )
                    }

                    <Row className="d-flex justify-content-end ">
                        <Col xs={12} md={12} lg={8} className="text-end ">
                            <Pagination
                                firstItem={windowWidth < 600 ? false : true}
                                lastItem={windowWidth < 600 ? false : true}
                                boundaryRange={windowWidth < 600 ? 0 : 1}
                                activePage={currentPage}
                                totalPages={Math.ceil(dataTrx.length / trxPerPage)}
                                onPageChange={handleChangePage}
                            />
                        </Col>
                    </Row>
                </Segment>

            )
        }
    }


    const handleAddPhoto = (e) => {
        let param = new FormData();

        param.append("image", e[0])
        param.append("id_customer", idCustomer)

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/customer.php/?index=9`,
            headers: { 'Content-Type': 'text/plain', 'authorization': token },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    setImageProf(res.data.image)
                    alert(res.data.message)
                } else {
                    alert(res.data.message)
                }
            })
    }

    const ProfileContent = () => (
        <Segment>
            <h3><Icon name="user outline" className="me-2" />Profile</h3>
            <Row>
                <Col md={4}>
                    <Image src={profile.avatar ? profile.avatar : 'https://via.placeholder.com/300x300.png'} fluid className="mb-3 rounded" />
                    <Frm.Input
                        type="file"
                        basic
                        fluid
                        icon="image"
                        className="mb-3"
                        onChange={(e) => handleAddPhoto(e.target.files)}
                    />
                </Col>
                <Col md={8}>
                    <h4>Personal Data</h4>
                    <div className="mb-3">
                        <List>
                            <List.Item>
                                <Row>
                                    <Col md={4}>Name</Col>
                                    <Col md={8}><div className="fw-bold text-capitalize">{profile.first_name} {profile.last_name}</div></Col>
                                </Row>
                            </List.Item>
                            <List.Item>
                                <Row>
                                    <Col md={4}>Date of Birth</Col>
                                    <Col md={8}><div className="fw-bold">{new Date(profile.dob).toLocaleDateString('en-US', formatLongDate)}</div></Col>
                                </Row>
                            </List.Item>
                            <List.Item>
                                <Row>
                                    <Col md={4}>Gender</Col>
                                    <Col md={8}><div className="fw-bold">{(profile.gender === "M" ? "Male" : "Female")}</div></Col>
                                </Row>
                            </List.Item>
                        </List>
                    </div>
                    <h4>Contact</h4>
                    <div>
                        <List>
                            <List.Item>
                                <Row>
                                    <Col md={4}>Email</Col>
                                    <Col md={8}>
                                        <span className="fw-bold me-3">{profile.email}</span>
                                        <span className="me-3">{(profile.verified == "1") ? <Label size="tiny" color="orange">Verified</Label> : <></>}</span>
                                    </Col>
                                </Row>
                            </List.Item>
                            <List.Item>
                                <Row>
                                    <Col md={4}>Phone Number</Col>
                                    <Col md={8}><div className="fw-bold">{(profile.address && profile.address.length > 0) ? profile.address[0].celular_number : <p className="text-muted">Not Set</p>}</div></Col>
                                </Row>
                            </List.Item>
                        </List>
                    </div>
                </Col>
            </Row>
        </Segment>
    )

    const updateList = (idProduct) => {
        setProductData(productData.filter(i => i.id_product !== idProduct))
    }

    const populateWishData = (data) => {
        return ( 
        <Segment>
            <h3><Icon name="heart outline" className="me-2" />Wishlist</h3>
            <Row>
                {
                    data.map((item, i) => {
                        return (
                            <ProductCard data={item} option='wishlist' key={i} updateList={updateList} />
                        )
                    })
                }
            </Row>
        </Segment>
        )
    }

    const Wishlist = ({ data }) => {
        
        if (data.length > 0) { 
            return (
                <Segment>
                    <h3><Icon name="heart outline" className="me-2" />Wishlist</h3>
                    <Row>
                        {
                            data.map((item, i) => {
                                return (
                                    <ProductCard data={item} option='wishlist' key={i} updateList={updateList} />
                                )
                            })
                        }
                    </Row>
                </Segment>
            )
        } else {
            return (
                <Segment>
                    <h3><Icon name="heart outline" className="me-2" />Wishlist</h3>
                    <p>No wishlisted product</p>
                </Segment>
            )
        }

    }

    const handleClickJoin = (e, point) => {
        e.preventDefault()

        const param = new URLSearchParams();
        param.append("phone", phone)
        param.append("identity", iden)
        param.append("identity_number", idenNumber)
        param.append("city", city)
        param.append("address", addr)
        param.append("isCorrectInput", checkCorrect)
        param.append("isAgreeTerm", checkTerm)
        param.append('id_customer', cart.customer.id_customer && idCustomer)
        param.append('first_point', point)

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/member.php/?index=1`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: param
        })
            .then(response => {
                if (response.data.state === 200) {
                    alert("Your member registration is successful")
                    setJoinForm(false)
                    setAllowReg("disalowed")
                    updateNotif(cart.customer.id_customer && idCustomer)
                }
            })
    }

    // const Membership = ({ profile, address }) => { 
    //     if (allowReg === "allowed") {
    //         return (
    //             <Segment>
    //                 <h3><Icon name="address card outline" className="me-2"/>Membership</h3>
    //                 <Row>
    //                     <Col>
    //                         <Button content="Join Membership" onClick={() => setJoinForm(true)} />
    //                     </Col>
    //                 </Row>

    //                 {/* <Modal show={termMember} backdrop='static' keyboard={false} onHide={hideTermMember} size="md">
    //                     <Modal.Header closeButton><span className="fs-5">Term of Membership</span></Modal.Header>
    //                     <Modal.Body>
    //                         <TermMember />
    //                     </Modal.Body>
    //                 </Modal> */}
    //             </Segment>
    //         )
    //     } else if (allowReg === "ismember") {
    //         let dobToDate = new Date(member.dob)

    //         return (
    //             <Segment>
    //                 <h3><Icon name="address card outline" className="me-2"/>Membership</h3>
    //                 <Row>
    //                     <Col md={12} className="mb-4">
    //                         <Row>
    //                             <Col md={12} className="fw-bold mb-2">Point Info</Col>
    //                             <Col md={4} className="">
    //                                 <div>Current Point</div>
    //                                 <div>Point used</div>
    //                             </Col>
    //                             <Col md={8} className="">
    //                                 <div className="fw-bold">: &nbsp;&nbsp;{global.Point.format(member.point_state)} Point</div>
    //                                 <div className="fw-bold">: &nbsp;&nbsp;{global.Point.format(member.point_used)} Point</div>
    //                             </Col>
    //                         </Row>
    //                     </Col>
    //                     <Col md={12} className="mb-4">
    //                         <Row>
    //                             <Col md={12}>
    //                                 <div className="fw-bold mb-2">Member Profile</div>
    //                             </Col>
    //                             <Col md={4}>
    //                                 <div>Id Member</div>
    //                                 <div>Identity</div>
    //                                 <div>Name</div>
    //                                 <div>Gender</div>
    //                                 <div>Email / Username</div>
    //                                 <div>DoB</div>
    //                                 {/* <div>Agree with membership term</div> */}
    //                             </Col>
    //                             <Col md={8}>
    //                                 <div className="fw-bold">: &nbsp;&nbsp;{member.id_member}</div>
    //                                 <div className="fw-bold">: &nbsp;&nbsp;{member.identity}&nbsp;{member.identity_number}</div>
    //                                 <div className="fw-bold">: &nbsp;&nbsp;{member.first_name}&nbsp;{member.last_name}</div>
    //                                 <div className="fw-bold">: &nbsp;&nbsp;{(member.gender === 'M') ? 'Male' : 'Female'}</div>
    //                                 <div className="fw-bold">: &nbsp;&nbsp;{member.email}</div>
    //                                 <div className="fw-bold">: &nbsp;&nbsp;{dobToDate.toLocaleDateString('en-US', dateOption)}</div>
    //                                 {/* <div className="fw-bold">: &nbsp;&nbsp;{member.isAgreeTerm == 'Y' ? 'Yes' : 'No'}</div> */}

    //                             </Col>
    //                         </Row>
    //                     </Col>
    //                 </Row>
    //             </Segment>
    //         )
    //     } else if (allowReg === "disalowed") {
    //         return (
    //             <Segment>
    //                 <h3><Icon name="address card outline" className="me-2"/>Membership</h3>
    //                 <Row>
    //                     <Col md={12} className="mb-3">
    //                         <Button content="Join Membership" onClick={() => showAlertJoin()} />
    //                     </Col>
    //                 </Row>

    //             </Segment>
    //         )
    //     }
    // }

    // const getOrderAddress = async (idOrder) => {
    //     const param = new URLSearchParams
    //     param.append('id_order', idOrder)

    //     await axios({
    //         method: "POST",
    //         url: `${global.SRV}api_hoops/controler/order.php/?index=10`,
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //         },
    //         data: param
    //     })
    //         .then(response => {
    //             if (response.data.state === 200) {
    //                 setOrderAddress(response.data.data)
    //             }
    //         })
    // }


    const setDefaultAddress = (idAddress, status) => {
        const param = new URLSearchParams();

        param.append('id_address', idAddress);
        param.append('status', status)
        param.append('id_customer', idCustomer)

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/customer.php/?index=4`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: param
        })
            .then((response) => {
                if (response.data.state === 200) {
                    getCustomerAddress(idCustomer)
                    // if (cart.customer.address !== null) {
                    //     if (!orderAddress) {
                    //         setUserAddress(cart.customer.address)
                    //     } else {
                    //         setUserAddress(orderAddress)
                    //     }
                    // } else if (cart.customer.address === null) {
                    //     if (!orderAddress) {
                    //         setUserAddress(response.data.data.filter(i => i.isDefault == 1)[0])
                    //     } else {
                    //         setUserAddress(orderAddress)
                    //     }
                    // }
                    setHideWarn(false)
                }
            })
    }


    const Address = (idCust) => {
        return (
            <Segment>
                <h3><Icon name="map marker alternate" />Shipping Address</h3>
                <Row>
                    <Col>
                        <Message
                            onDismiss={() => setHideWarn(true)}
                            size="small"
                            hidden={hideWarn}
                            success
                            icon='check circle outline'
                            header='Update address success.'
                            content='Make sure the address on your order page is correct, because this will not directly change the address on the order page.'
                        />
                    </Col>
                </Row>
                {addressList.length > 0 ?
                    addressList.map((item, i) => (
                        <section className={item.isDefault === '1' ? "callout-active" : "callout"} key={i}>
                            <Row className="d-flex justify-content-start align-items-middle">
                                <Col lg={10} md={10} sm={10}>
                                    <span className="fw-bold me-3">{item.address_label}</span>
                                    <span>{(item.isDefault == 1) ? <Label>Default</Label> : <></>}</span>
                                    <div>{item.recipient_name}</div>
                                    <div>{item.celular_number}</div>
                                    <div><span>{item.address_detail}</span> <span>{item.address_detail_dua}</span></div>
                                    <div>{item.destination}</div>
                                    <Button.Group basic className="mt-3" color="orange">
                                        <Button
                                            content="Edit Address"
                                            onClick={() => {
                                                setSelectedAddressEdit(item)
                                                if (setSelectedAddressEdit !== null) {
                                                    showAddressFormEdit();
                                                    closeAddressList()
                                                }
                                            }}
                                        />
                                        {(item.isDefault == 1) ? <></> :
                                            <Button
                                                content="Make Default"
                                                onClick={() => setDefaultAddress(item.id_address, (item.isDefault == 0) ? 1 : 0)} />}
                                    </Button.Group>
                                </Col>
                                {/* <Col lg={2} md={2} sm={2} className="d-flex justify-content-end align-self-center">
                                    {(item.id_address === selectedAddress.id_address) ?
                                        <FontAwesomeIcon className="text-muted fs-5" icon={faCheck} /> :
                                        <Button content="Select"
                                            onClick={() => {
                                                setUsedAddress(addressList, item.id_address)
                                                closeAddressList()
                                                updateOrderAddress(cart.summary.order_id, item.id_address)
                                            }} />}
                                </Col> */}
                            </Row>
                        </section>
                    ))
                    : <div className="text-center mb-4"><Image src={noDataImg} width={60} height={60}/></div>
                }
                <Row>
                    <Col>
                        {
                            addressState.allow ?
                                <Button
                                    color="orange"
                                    icon="plus"
                                    content="Add New Address"
                                    fluid
                                    basic
                                    onClick={() => {
                                        showAddressForm()
                                        // closeAddressList()
                                    }} />
                                :
                                <div className="border p-2 text-center small rounded"><i>Can't add more address, limited {addressState.limit} addresses per customer</i></div>
                        }
                    </Col>
                </Row>
            </Segment>
        )
    }

    const RenderedContent = () => {
        switch (activeMenu) {
            case "transaction": return <Transaction data={currentList} />; break;
            case "profile": return <ProfileContent />; break;
            case "wishlist": return <Wishlist data={wishData}/>; break;
            // case "membership": return <Membership profile={profile} address={cart.customer.address} />; break;
            case "address": return <Address idCust={idCustomer} />; break;
            default: if (showMenu) { if (showMenu === "trxActive") { return <Transaction data={dataTrx} /> } } else { return <ProfileContent /> }; break;
        }
    }

    const getProductData = async (id_customer, id_category, id_product) => {
        const param = new URLSearchParams()
        param.append('id_category', id_category)
        param.append('id_customer', id_customer)

        await axios({
            method: "POST",
            url: "https://hoopsdevteam.com/api_web/controler/product.php?index=1",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: param
        })
            .then((response) => {
                console.log(response.data)
                const data = response.data.data.filter(i => i.id_product === id_product)[0]
                let index = productData.findIndex(i => i.id_product == data.id_product)
                index === -1 && setProductData([...productData, data])
            })
    }


    const filterCity = (inputValue) => {
        // const compressedData = localStorage.getItem('_destination');
        // const decompressedData = JSON.parse(LZString.decompressFromBase64(compressedData));

        return optionCity.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        )
    }

    const promiseOptionsCity = (inputValue) => (
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(filterCity(inputValue));
            }, 1000)
        })
    );

    const getZipCode = (destination) => {
        const postalCode = allCity.filter((i) => i.id_destination === destination).map(i => i.zip_code)
        setZipCode(postalCode[0])
    }

    const getCustomerAddress = async (idCustomer) => {
        const param = new URLSearchParams()
        param.append('id_customer', idCustomer)

        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/customer.php/?index=3`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: param
        })
            .then((response) => {
                if (response.data.state === 200) {
                    setAddressList(response.data.data)
                }
            })
    }

    useEffect(() => {

        getCustomerAddress(idCustomer)

    }, [idCustomer])

    useEffect(() => {
        const customerTrx = async (idCust) => {
            const param = new URLSearchParams()
            param.append('id_customer', idCust)

            await axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/order.php/?index=16`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token,
                },
                data: param
            })
                .then(response => {
                    if (response.data.state == 200) {
                        let data = response.data.data;
                        setDataTrx(data)
                    }
                })
        }

        const getWish = (id_customer) => {
            const param = new URLSearchParams()

            param.append('id_customer', id_customer)
            if (wishData.length == 0) {
                axios({
                    method: "POST",
                    url: 'https://hoopsdevteam.com/api_web/controler/product.php?index=5',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'authorization': token
                    },
                    data: param
                })
                    .then(response => {
                        if (response.data.state === 200) {
                            setWishData(response.data.data)
                        } else {
                            return (<>Error..</>)
                        }
                    })
            }

        }

        if (idCustomer) {
            customerTrx(idCustomer)
            getWish(idCustomer)
        }

    }, [idCustomer])


    useEffect(() => {
        const profile = async (idCust) => {
            const param = new URLSearchParams()
            param.append('id_customer', idCust)

            await axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/customer.php/?index=8`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                },
                data: param
            })
                .then(response => {
                    if (response.data.state == 200) {
                        setProfile(response.data.data)
                    }
                })
        }

        if (idCustomer) {
            profile(idCustomer)
        }

        setPhone(cart.customer.address ? cart.customer.address.celular_number : '')
        setIden('KTP')
    }, [idCustomer, cart])

    useEffect(() => {
        // const generateCityOption = async () => {
        //     let option = []
        //     await axios({
        //         method: 'GET',
        //         url: `${global.SRV}api_hoops/controler/courier.php/?index=1`,
        //         headers: {
        //             'Content-Type': 'application/x-www-form-urlencoded',
        //             'authorization': token
        //         }
        //     })
        //         .then((response) => {
        //             if (response.data.length > 0) {
        //                 setAllCity(response.data)
        //                 response.data.map((item) => {
        //                     option.push({
        //                         label: `${item.sub_district}, ${item.district}, ${item.province}`,
        //                         value: item.id_destination,
        //                         zip_code: item.zip_code
        //                     })
        //                 })
        //             }
        //         })
        //         .catch(err => console.log(err))

        //     setOptionCity(option)
        //     try {
        //         // Kompresi data dan simpan di localStorage
        //         const compressedData = LZString.compressToBase64(JSON.stringify(option));
        //         localStorage.setItem('_destination', compressedData);
        //     } catch (error) {
        //         console.error('Error during compression and storage:', error);
        //     }
        // }

        const getMemberData = async (idMember) => {
            const param = new URLSearchParams()
            param.append('id_member', profile.id_member)
            await axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/member.php/?index=2`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                },
                data: param
            })
                .then(response => {
                    if (response.data.state === 200) {
                        setMember(response.data.data)
                    }
                })
        }

        // getMemberData(profile.id_member)
        // if (localStorage.getItem('_destination') == null) {
        //     generateCityOption()
        // }
    }, [profile])

    useEffect(() => {
        const getStatusMember = async (idCustomer) => {
            const param = new URLSearchParams()
            param.append('id_customer', idCustomer)

            await axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/order.php/?index=13`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                },
                data: param
            })
                .then(response => {
                    if (response.data.state === 200) {
                        setAllowReg(response.data.data.status)
                        setFirstPoint(response.data.data.first_point)
                    }
                })
        }

        const validateAddingAddress = async (idCust) => {
            const param = new URLSearchParams()
            param.append('id_customer', idCust)

            await axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/customer.php/?index=10`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                },
                data: param
            })
                .then(res => {
                    if (res.data.state === 200) {
                        setAddressState(res.data.data)
                    }
                })
        }


        getStatusMember(idCustomer)
        validateAddingAddress(idCustomer)

    }, [idCustomer])

    useEffect(() => {
        setImageProf(`https://ui-avatars.com/api/?name=${profile.first_name}+${profile.last_name}`)
    }, [profile])

    useEffect(() => {
        const generateDestinationOption = async () => {
            let option = []
            dataDestinasi.map((item, index) => {
                option.push({
                    label: `${item.sub_district}, ${item.district}, ${item.city}, ${item.province}`,
                    value: item.id_destination,
                    zip_code: item.zip_code
                })
            })
            setOptionCity(option)
        }
        generateDestinationOption()
        window.scrollTo(0, 0)
    }, [])

    return (
        <section>
            <Container style={windowWidth < 600 ? { paddingTop: "10vh", minHeight: "100vh" } : { paddingTop: "15vh", minHeight: "100vh" }}>
                <Row>
                    <Col md={12} className="mb-3">
                        <Alert className="text-start" variant="secondary" show={alertJoin} onClose={() => closeAlertJoin()} dismissible>
                            You cannot register as a member at this time, to register as a member you must make a minimum transaction of Rp. 1,500,000 not including shipping costs,
                            on the same day or a maximum of 1 day back and without any last transaction canceled.
                            for more detailed, you can click here <span className="text-primary" style={{ cursor: 'pointer' }} onClick={() => setTermMember(true)}>Term of Membership</span>
                        </Alert>
                    </Col>
                </Row>

                <Modal show={termMember} backdrop='static' keyboard={false} onHide={hideTermMember} size="md">
                    <Modal.Header closeButton><span className="fs-5">Term of Membership</span></Modal.Header>
                    <Modal.Body>
                        <TermMember />
                    </Modal.Body>
                </Modal>

                <Modal show={modalAddress} onHide={closeAddressForm} backdrop='static' keyboard={false} centered >
                    <Modal.Header closeButton>Add Address</Modal.Header>
                    <Modal.Body>
                        <AddressForm showModal={setModalAddress} getUserAddress={getCustomerAddress} isFirstAddress={true} />
                    </Modal.Body>
                </Modal>

                <Modal show={modalAddressEdit} onHide={closeAddressFormEdit} backdrop='static' keyboard='false' centered>
                    <Modal.Header closeButton>Edit Shipping Address</Modal.Header>
                    <Modal.Body>
                        <AddressForm
                            showModal={setModalAddressEdit}
                            getUserAddress={getCustomerAddress}
                            isFirstAddress={false}
                            editData={selectedAddressEdit}
                            setUserAddress={setUserAddress}
                        />
                    </Modal.Body>
                </Modal>

                <Modal show={joinForm} backdrop='static' keyboard={false} onHide={hideJoinForm} size="md">
                    <Modal.Header closeButton><span className="fs-5">Join Membership</span></Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12} xs={12}>
                                <Form onSubmit={(e) => handleClickJoin(e, firstPoint)} >
                                    <Row className="mb-5">
                                        <Col md={6} xs={12} className="mb-2">
                                            <Form.Group>
                                                <Form.Label>Firstname</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    minLength={2}
                                                    defaultValue={profile.first_name}
                                                    readOnly
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} xs={12} className="mb-2">
                                            <Form.Group>
                                                <Form.Label>Lastname</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    minLength={2}
                                                    defaultValue={profile.last_name}
                                                    readOnly
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} xs={12} className="mb-2">
                                            <Form.Group>
                                                <Form.Label>Phone</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text id="basic-addon1">+62</InputGroup.Text>
                                                    <Form.Control
                                                        type="text"
                                                        minLength={10}
                                                        defaultValue={phone}
                                                        onChange={(e) => handleChangePhoneNumber(e)}
                                                        required
                                                    />
                                                </InputGroup>
                                                <Form.Text>This must be valid or we won't be able to contact you</Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} xs={12} className="mb-2">
                                            <Form.Group>
                                                <Form.Label>Identification</Form.Label>
                                                <div className=" input-group mb-3">
                                                    <Form.Select size="sm" style={{ maxWidth: '4.6rem' }} onChange={(e) => setIden(e.target.value)} maxLength={16} minLength={12} required>
                                                        <option value="KTP">KTP</option>
                                                        <option value="SIM">SIM</option>
                                                    </Form.Select>
                                                    <Form.Control
                                                        type="text"
                                                        minLength={10}
                                                        defaultValue={idenNumber}
                                                        onChange={(e) => handleChangeIndeNumber(e)}
                                                        required
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} xs={12} className="mb-2">
                                            <Form.Group>
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control type="text" maxLength={100} minLength={10} onChange={(e) => setAddr(e.target.value)} required />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} xs={12} className="mb-2">
                                            <Form.Group>
                                                <Form.Label>City</Form.Label>
                                                <AsyncSelect
                                                    isClearable
                                                    cacheOptions
                                                    defaultOptions
                                                    isLoading={isLoadOption}
                                                    loadOptions={promiseOptionsCity}
                                                    onChange={(value, action) => {
                                                        setCity((value) ? value.label : null)
                                                        getZipCode((value) ? value.value : null)
                                                    }}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} xs={12} className="mt-4">
                                            <Form.Group>
                                                <Form.Check
                                                    label="I believe the information I filled in is correct and I am responsible for all of that"
                                                    defaultChecked={checkCorrect}
                                                    onChange={(e) => setCheckCorrect(e.target.checked)}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} xs={12} className="mt-1">
                                            <Form.Group>
                                                <Form.Check label={
                                                    <>
                                                        I agree to the existing &nbsp;
                                                        <Link onClick={() => { setTermMember(true); setJoinForm(false) }}>membership terms</Link>
                                                    </>
                                                }
                                                    onChange={(e) => setCheckTerm(e.target.checked)}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button content="Join Now" fluid />
                                        </Col>
                                    </Row>

                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                {
                    (selectedOrder) &&
                    <Modal show={orderDetail} backdrop='static' keyboard={false} onHide={hideOrderDetail} size="lg" fullscreen={windowWidth < 1000 ? true : false}>
                        <Modal.Header closeButton><span className="fs-5">Detail Transaction</span></Modal.Header>
                        <Modal.Body style={{ backgroundColor: "whitesmoke" }}>
                            <div style={{ border: 1, borderRadius: 5, backgroundColor: "white", padding: 20, marginBottom: 12 }}>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Row>
                                            <Col md={12}>
                                                <Accordion>
                                                    <Accordion.Title
                                                        style={{ paddingTop: 0 }}
                                                        active={activeIndex}
                                                        onClick={(e) => handleClickAccord(e)}
                                                    >
                                                        <Row className="d-flex">
                                                            <Col xs={6} sm={6} md={6}>
                                                                <div><Label size="mini" color="orange">{selectedOrder.order_state}</Label></div>
                                                            </Col>
                                                            <Col xs={6} sm={6} md={6}>
                                                                <div className="text-end" onClick={() =>
                                                                    !openTrk ?
                                                                        trackingJNE(awbNumber)
                                                                        : setOpenTrk(false)
                                                                }>
                                                                    <><span>Tracking</span> <span className="text-muted">(Click here)</span>
                                                                        <Icon name='dropdown' /></>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Accordion.Title>
                                                    <Accordion.Content active={activeIndex} className="text-start">
                                                        <Segment>
                                                            <section className="timeline">
                                                                <ul>
                                                                    {
                                                                        (history) ?
                                                                            history.map((item, i) => {
                                                                                return (
                                                                                    <li>
                                                                                        <Row className="">
                                                                                            <Col xs={12}>
                                                                                            <div className="fw-bold">{item.date}</div>
                                                                                            <div style={windowWidth < 600 ? {width:'15rem'} : {width:'100%'}}>{item.desc}</div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                            : <>
                                                                                <p>Message : <i>{typeof awbResponse !== 'undefined' ? awbResponse : 'Resi not generate yet'}</i></p>
                                                                                <p>There is no tracking data yet, it is possible that the AWB has not been input by the agent. </p>
                                                                            </>
                                                                    }
                                                                </ul>
                                                            </section>
                                                        </Segment>
                                                    </Accordion.Content>
                                                </Accordion>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col xs={6} sm={6} md={6}>Order Number</Col>
                                            <Col xs={6} sm={6} md={6} className="text-end fw-bold">{selectedOrder.id_order}</Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} sm={6} md={6}>Transaction Date</Col>
                                            <Col xs={6} sm={6} md={6} className="text-end fw-bold">{new Date(selectedOrder.order_date).toLocaleDateString('en-US', formatLongDate)}</Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ border: 1, borderRadius: 5, backgroundColor: "white", padding: 20, marginBottom: 12 }}>
                                <Row className="pb-4">
                                    <div className="fw-bold mb-2">Product Details</div>
                                    <Col md={9} className="border-end">
                                        {
                                            selectedOrder.product_details.map((item, i) => (
                                                <Stack direction="horizontal" gap={2} className="mb-3" key={i}>
                                                    <Image src={item.image_order ? item.image_order : 'https://via.placeholder.com/500x400.png'} width={64} rounded />
                                                    <Stack direction="vertical">
                                                        <div className="fw-bold">{item.product_name || item.name_order}</div>
                                                        <div>
                                                            <span className="me-2">{global.IDR.format(item.total_price_order)}</span>
                                                            {/* <span className="me-3 text-muted"><s>{global.IDR.format(item.price)}</s></span> */}
                                                            <div>
                                                                <span className="me-3">Qty : {item.qty_order}</span>
                                                                <span className="me-3">Size : {item.size_order}</span>
                                                            </div>
                                                        </div>
                                                    </Stack>
                                                </Stack>
                                            ))
                                        }

                                    </Col>
                                    <Col md={3} className="align-self-center">
                                        <Row className="text-center">
                                            <Col md={12} >
                                                <div>Total Order</div>
                                                <div className="fw-bold">{global.IDR.format(selectedOrder.total_payment)}</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ border: 1, borderRadius: 5, backgroundColor: "white", padding: 20, marginBottom: 12 }}>
                                <Row>
                                    <div className="fw-bold mb-2">Delivery Info</div>
                                    <Col md={12}>
                                        <Row className="mb-2">
                                            <Col xs={4} sm={2} md={2}>Courier</Col>
                                            <Col xs={1} sm={1} md={1} className="text-end">:</Col>
                                            <Col xs={7} sm={9} md={9} className="fw-bold">{selectedOrder.courier_name} - {selectedOrder.id_service}</Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col xs={4} sm={2} md={2}>Resi Number</Col>
                                            <Col xs={1} sm={1} md={1} className="text-end">:</Col>
                                            <Col xs={7} sm={9} md={9} className="fw-bold">{selectedOrder.no_resi}</Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col xs={4} sm={2} md={2}>Address</Col>
                                            <Col xs={1} sm={1} md={1} className="text-end">:</Col>
                                            <Col xs={7} sm={9} md={9} className="fw-bold">
                                                <Stack direction="vertical">
                                                    <Stack direction="horizontal" gap={3} className="mb-2">
                                                        <div>{selectedOrder.recipient}</div>
                                                        <div><Label size="tiny">{selectedOrder.address_label}</Label></div>
                                                    </Stack>
                                                    <div className="fw-light">{selectedOrder.phone}</div>
                                                    <div className="fw-light"><span>{selectedOrder.address_detail}</span> <span>{selectedOrder.address_detail_dua}</span>,</div>
                                                    <div className="fw-light">{selectedOrder.sub_district}, {selectedOrder.district}, {selectedOrder.city}</div>
                                                    <div className="fw-light">{selectedOrder.province} {selectedOrder.zip}</div>
                                                </Stack>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ border: 1, borderRadius: 5, backgroundColor: "white", padding: 20, marginBottom: 12 }}>
                                <Row>
                                    <div className="fw-bold mb-2">Payment Details</div>
                                    <Col xs={6} sm={6} md={6}>
                                        <div>Payment Method</div>
                                    </Col>
                                    <Col xs={6} sm={6} md={6}>
                                        <div className="fw-bold text-uppercase text-end">{selectedOrder.payment_method} - {selectedOrder.bank}</div>
                                    </Col>
                                    <Col md={12}><Divider clearing /></Col>
                                    <Col xs={6} sm={6} md={6}>
                                        <div>Total Item</div>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="fw-bold text-end">{global.IDR.format(selectedOrder.total_price)}</Col>
                                    <Col xs={6} sm={6} md={6}>
                                        <div>Voucher</div>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="fw-bold text-end">{global.IDR.format(selectedOrder.total_voucher)}</Col>
                                    <Col xs={6} sm={6} md={6}>
                                        <div>Shipping Voucher</div>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="fw-bold text-end">{global.IDR.format(selectedOrder.shipping_voucher)}</Col>
                                    <Col xs={6} sm={6} md={6}>
                                        <div>Delivery Cost</div>
                                    </Col>
                                    {selectedOrder.shipping_voucher > 0 ?
                                        <Col xs={6} sm={6} md={6} className="text-end"><span>({global.IDR.format(parseFloat(selectedOrder.shipping_voucher) + parseFloat(selectedOrder.courier_cost))})</span>&nbsp;<span className="fw-bold text-end">{global.IDR.format(selectedOrder.courier_cost)}</span></Col>
                                        :
                                        <Col xs={6} sm={6} md={6} className="text-end fw-bold">{global.IDR.format(selectedOrder.courier_cost)}</Col>
                                    }
                                    <Col xs={6} sm={6} md={6}>
                                        <div>Shipping Insurance</div>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="text-end fw-bold">{global.IDR.format(selectedOrder.asuransi)}</Col>
                                    <Col md={12}><Divider clearing /></Col>
                                    <Col xs={6} sm={6} md={6}>
                                        <div>Total Order</div>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="fw-bold text-end">{global.IDR.format(selectedOrder.total_payment)}</Col>
                                </Row>
                            </div>
                        </Modal.Body>
                    </Modal>
                }
                <Row className="text-start">
                    <Col xs={12} sm={12} md={12} lg={4} xl={3}>
                        <Menu vertical fluid size="large" className="mb-3" style={{ fontSize: '1.4rem', fontFamily: 'Poppins, sans-serif' }}>
                            <Menu.Item>
                                <Menu.Header>
                                    <Stack direction="horizontal" gap={3}>
                                        <Image src={profile.avatar !== '' ? profile.avatar : imageProf} height={50} width={50} rounded />
                                        <div className="fs-5">
                                            <Stack direction="vertical" gap={2}>
                                                <div className="text-capitalize">{profile && profile.first_name + ' ' + profile.last_name}</div>
                                                {/* <div><Label size="mini">{profile && (profile.isMember == "Y") ? "Member" : "Customer"}</Label></div> */}
                                                <div>
                                                    {profile && profile.isMember == "Y" ?
                                                        <>
                                                            <Stack direction="horizontal" gap={2}>
                                                                <Label >Customer</Label>
                                                                <Label color="orange">Member</Label>
                                                            </Stack>
                                                        </>
                                                        :
                                                        <Label size="mini">Customer</Label>
                                                    }
                                                </div>
                                            </Stack>
                                        </div>
                                    </Stack>
                                </Menu.Header>
                            </Menu.Item>
                            <Menu.Item>
                                <Menu.Menu>
                                    <Menu.Item
                                        name="profile"
                                        active={activeMenu === "profile"}
                                        onClick={() => handlMenuClick("profile")}
                                    />
                                    <Menu.Item
                                        name="transaction"
                                        active={activeMenu === "transaction"}
                                        onClick={() => handlMenuClick("transaction")}
                                    />
                                    <Menu.Item
                                        name="wishlist"
                                        active={activeMenu === "wishlist"}
                                        onClick={() => handlMenuClick("wishlist")}
                                    />
                                    {/* <Menu.Item
                                        name="membership"
                                        active={activeMenu === "membership"}
                                        onClick={() => handlMenuClick("membership")}
                                    /> */}
                                    <Menu.Item
                                        name="address"
                                        active={activeMenu === "address"}
                                        onClick={() => handlMenuClick("address")}
                                    />
                                </Menu.Menu>
                            </Menu.Item>
                        </Menu>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={9}>
                        <RenderedContent />
                    </Col>
                </Row>

            </Container>
        </section>
    )
}

export default Profile