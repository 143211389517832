

import { useState } from 'react';
import {
    Badge,
    ButtonGroup,
    Col,
    Container,
    Image,
    Row,
    Stack
} from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Dimmer, Grid, Icon, Label, Loader, Popup } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { useEffect } from 'react';
import axios from 'axios';
import { useMyGlobal } from '../../component/GlobalData';
import { formatNumberWithCommas } from '../../component/utility';
import { Toast } from '../../component/Toast';
import { Helmet } from 'react-helmet';
import logoHoops from '../../assets/logo_hoops_opa.png';

const ProductPage = ({ pickedProduct }) => {
    const navigate = useNavigate()
    const products = useLocation().state;
    const [dataPage, setDataPage] = useState(null)
    const [laoderProduct, setLoaderProduct] = useState(false)
    const [selectedSize, setSelectedSize] = useState(null)
    const [selectedColorId, setSelectedColorId] = useState(null)
    const [selectedColor, setSelectedColor] = useState(null)
    const [activeSize, setActiveSize] = useState(null)
    const ses = localStorage
    const [sortDataSize, setSortDataSize] = useState([])
    const [sortDataSizeGroup, setSortDataSizeGroup] = useState([])
    const [mssgTxt, setMsgTxt] = useState('')

    const [defaultImage, setDefaultImage] = useState()
    const [productImage, setProductImage] = useState([])
    const [productDiscount, setProductDiscount] = useState()
    const [discountTotal, setDiscountTotal] = useState()
    const [isOrdered, setIsOrdered] = useState(null)
    const idCustomer = (localStorage.getItem('loginData')) ? JSON.parse(localStorage.getItem('loginData')).id : null
    const [wish, setWish] = useState(null)
    const [sizeQty, setSizeQty] = useState('')
    const token = (localStorage.getItem('loginData')) ? JSON.parse(localStorage.getItem('loginData')).token : null
    const { isPortrait, windowWidth } = useMyGlobal()
    const [login, setLogin] = useState(null)
    const [coLoad, setCoLoad] = useState(false)
    const [atcLoad, setAtcLoad] = useState(false)
    const { productId } = useParams()

    const percentTag = {
        alignItems: 'center',
        background: 'linear-gradient(to right, #cc3b21, #FFA500)',
        fontSize: '0.714286rem',
        color: 'rgb(255, 255, 255)',
        fontWeight: 600,
        fontSize: '0.714286rem',
        borderRadius: '0 0 5px 0px',
    }

    const percentTagNormal = {
        alignItems: 'center',
        background: 'linear-gradient(to right, grey, lightgrey)',
        fontSize: '0.714286rem',
        color: 'rgb(255, 255, 255)',
        fontWeight: 600,
        fontSize: '0.714286rem',
        borderRadius: '0 0 5px 0px',
    }

    const bodyStyle = {
        backgroundImage: `url(${logoHoops})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    }

    const setProductDetail = (dataProduct) => {
        setDefaultImage(dataProduct.image.length > 0 ? dataProduct.image.filter(i => i.is_default == 1).map(i => i.image) : 'https://via.placeholder.com/500x400.png')
        setProductImage(dataProduct.image.map(i => i.image))
        // setdataProductDiscount((dataProduct.discount) ? dataProduct.discount.discount_value : 0)
        setDiscountTotal((dataProduct.discount) ? dataProduct.discount.discount_total : 0)

        const handleProductDsc = () => {
            if (dataProduct.isDiscounted === '1' && dataProduct.fs_on) {
                setProductDiscount(dataProduct.fs_discount)
            } else if (dataProduct.isDiscounted === '1' && !dataProduct.fs_on) {
                setProductDiscount(dataProduct.discount.discount_value)
            } else if (dataProduct.isDiscounted === '0' && dataProduct.fs_on) {
                setProductDiscount(dataProduct.fs_discount)
            } else if (dataProduct.isDiscounted === '0' && !dataProduct.fs_on) {
                setProductDiscount(0)
            }
        }

        const handleDiscountTotal = () => {
            if (dataProduct.isDiscounted === '1' && dataProduct.fs_on) {
                setDiscountTotal(dataProduct.fs_discount_value)
            } else if (dataProduct.isDiscounted === '1' && !dataProduct.fs_on) {
                setDiscountTotal(dataProduct.discount.discount_total)
            } else if (dataProduct.isDiscounted === '0' && dataProduct.fs_on) {
                setDiscountTotal(dataProduct.fs_discount_value)
            } else if (dataProduct.isDiscounted === '0' && !dataProduct.fs_on) {
                setDiscountTotal(0)
            }
        }

        handleProductDsc()
        handleDiscountTotal()
    }


    const handleClickSize = (size) => {
        setSelectedSize(size)
        if (dataPage.qty > 0) {
            setSizeQty(dataPage.barcode.filter(o => o.size == size)[0].qty)
        } else {
            setSizeQty(0)
        }
    }

    const handleClickWishlist = (id_customer, id_product, id_category) => {
        const param = new URLSearchParams()
        param.append('id_customer', id_customer)
        param.append('id_product', id_product)
        param.append('id_category', id_category)
        axios({
            method: "POST",
            url: "https://hoopsdevteam.com/api_web/controler/product.php?index=6",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: param
        }).
            then(response => {
                if (response.data.state === 200) {
                    setWish(true)
                    Toast.fire({
                        icon: "success",
                        text: "Wishlist updated",
                        position: "top-end",
                    })
                }
            })
    }

    // const getIsWish = async (id_customer, id_product) => {
    //     const param = new URLSearchParams()
    //     param.append('id_customer', id_customer)
    //     param.append('id_product', id_product)

    //     await axios({
    //         method: "POST",
    //         url: "https://hoopsdevteam.com/api_web/controler/product.php?index=7", // OVER REQUEST
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //             'authorization': token
    //         },
    //         data: param
    //     })
    //         .then(response => {
    //             console.log(response.data)
    //             if (response.data.state === 200) {
    //                 (response.data.exist === 1) ? setWish(true) : setWish(false)
    //             }
    //         })
    // }

    const addProductCart = (from) => {
        let cartData = JSON.parse(localStorage.getItem('loginData'))
        if (!cartData.hasOwnProperty('id_cart') && cartData.id_cart != 'undefined') {
            alert("Please try to logout & login again, after that you can add items to your basket, but don't worry this only happens when you first register")
        } else {
            from == 'co' ? setCoLoad(true) : setAtcLoad(true)
            const param = new URLSearchParams()
            param.append('id_customer', login.id)
            param.append('id_cart', JSON.parse(localStorage.getItem('loginData')).id_cart)
            param.append('id_product', dataPage.id_product)
            param.append('id_color', selectedColor.id_color)
            param.append('size', selectedSize)
            param.append('qty', 1)
            param.append('barcode', dataPage.barcode.filter(i => i.size === selectedSize)[0]['barcode'])
            param.append('is_fs', dataPage.fs_on)
            param.append('final_price', dataPage.final_price)

            axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/cart.php/?index=2`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                },
                data: param
            }).then(res => {
                if (from == 'co') {
                    setCoLoad(false)
                    navigate('/cart')
                } else {
                    setAtcLoad(false)
                }
            })
        }
    }

    const slcProduct = (color, slcSize) => {
        return {
            'id_warehouse': dataPage.id_warehouse,
            'product_id': dataPage.id_product,
            'product_name': dataPage.product_name,
            'product_desc': dataPage.product_desc,
            'product_dimension': dataPage.dimension,
            'product_image': dataPage.image.filter(i => i.is_default == 1)[0],
            'price': dataPage.price,
            'is_discounted': dataPage.isDiscounted,
            'id_discount': dataPage.id_discount,
            'id_gender': dataPage.id_gender,
            'gender_name': dataPage.gender_name,
            'id_segment': dataPage.id_segment,
            'segment_name': dataPage.segment_name,
            'discount': dataPage.discount,
            'final_price': dataPage.final_price,
            'point': dataPage.point,
            'selected_color': color,
            'selected_size': slcSize,
            'qty': 1,
            'barcode': dataPage.barcode.filter(i => i.size === selectedSize)[0]['barcode'],
            'flashsale': dataPage.hasOwnProperty('flashsale') ? (dataPage.flashsale.length > 0 ? dataPage.flashsale : []) : [],
            "fs_discount": (dataPage.hasOwnProperty('fs_discount')) ? dataPage.fs_discount : 0,
            "fs_percent": (dataPage.hasOwnProperty('fs_percent')) ? dataPage.fs_percent : 0,
            "fs_on": dataPage.discount_flag === 'FS' ? true : false,
        }
    }

    const ImageChild = ({ src }) => {
        return src.map((item, i) => (
            <Col md={3} sm={3} xs={3} onClick={() => setDefaultImage(item)} key={i} className="mb-4">
                <Image src={item} fluid rounded style={{ cursor: 'pointer' }} />
            </Col>
        ))
    }

    const ImageMain = ({ src }) => {
        return (
            <Col sm={12} md={12}>
                <Image src={src} fluid rounded />
            </Col>
        )
    }

    const previousPriceCoret = (data) => {
        if (data?.isDiscounted == 1 && parseInt(data?.discount.discount_value) > 0) {
            return (<s>{global.IDR.format(data?.price)}</s>)
        } else if ((data?.hasOwnProperty('flashsale') || data?.fs_percent > 0) && data?.isDiscounted == '1' || data?.fs_on) {
            return (<s>{global.IDR.format(data.price)}</s>)
        } else if (data?.isDiscounted == 1 && parseInt(data?.discount.discount_value) === 0) {
            return ''
        }
    }

    useEffect(() => {
        const param = new URLSearchParams()
        param.append('id_customer', idCustomer)

        const cartState = async () => {
            await axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/cart.php/?index=9`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                },
                data: param
            })
                .then(response => {
                    if (response.data.state == 200) {
                        setIsOrdered(response.data.data)
                    }
                })
        }

        cartState()
    }, [idCustomer])

    useEffect(() => {
        const param = new URLSearchParams()
        // let url = window.location.href
        // let urlSegments = url.split('/');
        // let id_product = decodeURIComponent(urlSegments[urlSegments.length - 1]);
        // let uri = new URL(window.location.href);
        // let id_product = uri.pathname.split('/').pop();

        const getProductData = async (idProduct) => {
            setLoaderProduct(true)
            param.append('id_product', idProduct)
            if (idCustomer) {
                param.append('id_customer', idCustomer)
            } else {
                param.append('id_customer', '')
            }
            await axios({
                method: "POST",
                url: `https://hoopsdevteam.com/api_web/controler/product.php?index=16`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                },
                data: param
            })
                .then(resProd => {

                    let data = resProd.data.data[0]
                    if (resProd.data.data.length > 0) {
                        const groupedData = []
                        const numericSizes = data.barcode.filter(item => !isNaN(parseFloat(item.size)));
                        const nonNumericSizes = data.barcode.filter(item => isNaN(parseFloat(item.size)));
                        if (numericSizes.length > 0) {
                            const size = data.barcode.sort((a, b) => parseFloat(a.size) - parseFloat(b.size))
                            for (let i = 0; i < size.length; i += 6) {
                                groupedData.push(size.slice(i, i + 6));
                            }
                            setSortDataSizeGroup(groupedData)
                            setSortDataSize(size)
                        } else if (nonNumericSizes.length > 0) {
                            const sortNonNumeric = (a, b) => {
                                const sizeOrder = { "S": 1, "M": 2, "L": 3, "XL": 4, "XXL": 5 };
                                return sizeOrder[a.size] - sizeOrder[b.size];
                            }
                            const size = data.barcode.sort(sortNonNumeric)
                            for (let i = 0; i < size.length; i += 6) {
                                groupedData.push(size.slice(i, i + 6));
                            }
                            setSortDataSizeGroup(groupedData)
                            setSortDataSize(size)
                        }

                        if (!data.hasOwnProperty('included_on_voucher')) {
                            const getVoucherProduct = async () => {
                                await axios({
                                    method: "GET",
                                    url: "https://hoopsindonesia.co.id/api_hoops/controler/voucher.php?index=3",
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded',
                                        'authorization': token
                                    },
                                })
                                    .then(res => {
                                        if (res.data.state === 200) {
                                            let voucherTemp = []
                                            res.data.data.forEach(i => {
                                                if (data.id_product == i.id_product) {
                                                    voucherTemp.push(i)
                                                }
                                            })
                                            data.included_on_voucher = voucherTemp
                                           
                                            setDataPage(data)
                                            setLoaderProduct(false)
                                          
                                        }
                                    })
                            }
                            getVoucherProduct()
                        }

                        setProductDetail(data)
                        setLogin(JSON.parse(ses.getItem('loginData')))
                        window.scrollTo(0, 0)
                        if (data.color.length == 1) {
                            setSelectedColor(data.color[0])
                        }

                        if (data.size.length == 1) {
                            let size = data.size
                            setSelectedSize(data.size[0])
                            if (data.qty > 0) {
                                setSizeQty(data.barcode.filter(o => o.size == size)[0].qty)
                            } else {
                                setSizeQty(0)
                            }
                        }
                        setLoaderProduct(false)
                    } else {
                        setLoaderProduct(false)
                        setMsgTxt('Product Not Found')
                    }
                })
        }
        getProductData(productId)

    }, [])

    return (
        <>
            <Dimmer active={laoderProduct}>
                <Loader inverted>Loading Product...</Loader>
            </Dimmer>
            {
            dataPage ? 
            <Container className='mt-5 mb-5 pt-5' fluid='sm' style={{ minHeight: '100vh', height: '100%' }}>
                <Helmet>
                    <script>
                        {`
                        fbq("track", "ViewContent", {
                            content_ids: '${dataPage?.id_product}',
                            content_name: '${dataPage?.product_name}',
                            content_category: '${dataPage?.category_name[0]}',
                            content_type: 'product_group',
                            value: '${dataPage?.final_price}', 
                            currency: 'IDR'
                        });
                    `}
                    </script>
                </Helmet>
                <Row className={windowWidth < 600 ? 'pt-1' : 'pt-5'} style={bodyStyle}>
                    <Col xs={12} sm={12} md={5} lg={5} className={windowWidth < 600 ? 'pe-2 pt-1' : 'pe-5 pt-4'}>
                        <Row className='mb-4'>
                            <ImageMain src={typeof defaultImage != 'undefined' ? defaultImage : 'https://via.placeholder.com/500x400.png'} />
                        </Row>
                        <Row>
                            <ImageChild src={productImage} />
                        </Row>
                    </Col>
                    <Col md={6} lg={7} className='ps-3 pt-4'>
                        <Row className='mb-2'>
                            <Col md={12} lg={12}>
                                <Row>
                                    <Col xs={9} sm={9} md={9} lg={9}>
                                        <div className='text-start fs-5 fw-bold'>{dataPage?.product_name}</div>
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} className="text-end">
                                        {
                                            (idCustomer) &&
                                                (dataPage?.discount_flag == "FS") ? <></> :
                                                (!dataPage?.is_wishlist) ?
                                                    <Popup content="Add to wishlist" trigger={<Button basic icon="heart outline" onClick={() => handleClickWishlist(idCustomer, dataPage?.id_product, dataPage?.id_category)} />} />
                                                    : <Label icon='like' style={{ color: '#FF5733' }} content="Wishlisted" basic />
                                        }
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={12} className="text-start">
                                <span className='pe-3'>{Array.isArray(dataPage?.category_name) ? dataPage?.category_name.length > 1 ? dataPage?.category_name.map(o => o + ', ') : dataPage?.category_name : dataPage?.category_name}</span>
                                <span className='me-3'><Label size="small">{dataPage?.segment_name}</Label></span>
                                <span className='me-3'><Label size="small">{dataPage?.id_product}</Label></span>
                                {pickedProduct.qty <= 0 ? <span className='me-3'><Label as="a" tag size='small' color="orange">Soldout</Label></span> : <></>}
                            </Col>
                        </Row>
                        <Row className='pt-1'>
                            <Col md={12} lg={12}>
                                <div className='text-start'>{dataPage?.product_desc}</div>
                            </Col>
                        </Row>
                        <Row className='pt-3'>
                            <Col className='text-start'>
                                <span className='text-start fs-5 fw-semibold me-3'>{global.IDR.format(dataPage?.final_price)}</span>
                                <span className='text-start fs-5 fw-light text-muted'>{previousPriceCoret(dataPage)}</span>
                            </Col>
                        </Row>
                        <Row className=''>
                            <Col className='text-start mt-2 mb-1' >
                                {
                                    productDiscount > 0 ?
                                        <span><Label size="small" style={percentTag}>{productDiscount}%</Label></span>
                                        :
                                        <Popup
                                            content="Normal Price"
                                            size='mini'
                                            trigger={
                                                <span><Label size='small' style={percentTagNormal}>No Dscn.</Label></span>
                                            } />
                                }
                                {/* <span className='text-muted'><small>Dsc. {formatNumberWithCommas(discountTotal)}</small></span> */}
                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-start'>
                            <Col className='text-start' xl={4} lg={6} md={12} sm={12} xs={12}>
                                {
                                    dataPage?.included_on_voucher &&
                                    dataPage?.included_on_voucher.map((item, i) => (
                                        <Popup trigger={
                                            <div key={i} className='fw-bold' style={{ color: '#F7634C' }}><Icon name='tag' />{item.name} -{item.type == 'D' ? item.percent : item.nominal}% off</div>
                                        }
                                            content={`This item is included in the voucher ${item.name}`}
                                            size='mini'
                                        />
                                    ))
                                }
                            </Col>
                        </Row>
                        {/* <Row className='mt-3'>
                        <Col className='text-start' md={12} lg={12}>
                            <Stack direction='horizontal'>
                                <Label size='medium'>+{dataPage?.point} Point</Label>
                                <span className='text-muted ms-2'><small>You will get bonus points if you pay for this product</small></span>
                            </Stack>
                        </Col>
                    </Row> */}
                        <Row className='mt-4 text-start'>
                            <div className='mb-1'>Size Group : {(dataPage?.size_group) ? <span className='fw-bold'>{dataPage?.size_group}</span> : <span className='text-muted'>not set</span>}</div>
                            <label>Size : {dataPage?.qty > 0 ? (selectedSize) ? <span className='fw-bold'>{selectedSize}</span> : <span className='text-muted'>not selected</span> : <span className='text-muted'>not available</span>}</label>
                            {
                                windowWidth < 600 ?
                                    sortDataSizeGroup.map((group, i) => {
                                        return (
                                            <Col className='text-start mt-2' sx={12} sm={12} md={12} lg={12}>
                                                <Button.Group key={i}>
                                                    {
                                                        group.map((item, g) => (
                                                            <Button
                                                                value={item.size}
                                                                onClick={e => handleClickSize(e.target.value)}
                                                                key={g}
                                                                className={(selectedSize == item.size) ? 'active' : ''}
                                                            >
                                                                {item.size}
                                                            </Button>
                                                        ))
                                                    }
                                                </Button.Group>
                                            </Col>
                                        )
                                    })
                                    :
                                    <Col className='text-start mt-2' sx={12} sm={12} md={12} lg={12}>
                                        <Button.Group>
                                            {
                                                sortDataSize.map((item, i) => (
                                                    <Button
                                                        value={item.size}
                                                        onClick={e => handleClickSize(e.target.value)}
                                                        key={i}
                                                        className={(selectedSize == item.size) ? 'active' : ''}
                                                    >
                                                        {item.size}
                                                    </Button>

                                                ))
                                            }
                                        </Button.Group>
                                    </Col>
                            }
                            <Col className='text-start mt-2 mb-4' md={12} lg={12}>
                                {
                                    dataPage?.qty > 0 ?
                                        sizeQty == 1 ? <div className='text-danger'><Icon name='exclamation' />Only 1 left</div> : <><Icon name='check circle outline' /> Available</>
                                        :
                                        <></>
                                }
                            </Col>
                            <Col className='text-start mt-1' md={12} lg={12}>
                                <Button content="Size Guide" color='black' icon="external alternate" basic size='tiny' labelPosition='right' onClick={() => navigate('/home/sizeGuide')} />
                            </Col>
                        </Row>
                        <Row className='mt-3 text-start'>
                            <label>Color variation : &nbsp;&nbsp;{
                                (selectedColor) ?
                                    <span>
                                        <canvas width={10} height={10} style={{ backgroundColor: selectedColor.color_main }} key={1} />
                                        <canvas width={10} height={10} style={{ backgroundColor: selectedColor.color_secondary }} key={2} />
                                        <canvas width={10} height={10} style={{ backgroundColor: selectedColor.color_accent }} key={3} />
                                    </span>
                                    :
                                    dataPage?.qty > 0 ?
                                        <span className='text-muted'>not selected</span>
                                        : <span className='text-muted'>not available</span>
                            }</label>
                            <Col className='text-start mt-2'>
                                {
                                    dataPage?.qty > 0 ?
                                        <Button.Group>
                                            {
                                                dataPage?.color.map((item, i) => (
                                                    <Button
                                                        value={item.id_color}
                                                        onClick={(e) => {
                                                            setSelectedColorId(e.target.value)
                                                            setSelectedColor(item)
                                                        }}
                                                        key={i}
                                                        className={(selectedColorId == item.id_color) ? 'active' : ''}
                                                    >
                                                        {
                                                            <section
                                                                style={{
                                                                    paddingLeft: 4,
                                                                    paddingRight: 4,
                                                                    paddingTop: 2,
                                                                    paddingBottom: 1,
                                                                    background: "whitesmoke",
                                                                    zIndex: 1
                                                                }}
                                                            >
                                                                <canvas
                                                                    width={10}
                                                                    height={10}
                                                                    style={{ backgroundColor: item.color_main }}
                                                                    key={1}
                                                                ></canvas>
                                                                <canvas
                                                                    width={10}
                                                                    height={10}
                                                                    style={{ backgroundColor: item.color_secondary }}
                                                                    key={2}
                                                                ></canvas>
                                                                <canvas
                                                                    width={10}
                                                                    height={10}
                                                                    style={{ backgroundColor: item.color_accent }}
                                                                    key={3}
                                                                ></canvas>
                                                            </section>
                                                        }
                                                    </Button>
                                                ))
                                            }
                                        </Button.Group>
                                        :
                                        <></>
                                }
                            </Col>
                        </Row>
                        <Row className='mt-5 text-start'>
                            <Col sx={10} sm={12} md={12} lg={6}>
                                {
                                    dataPage?.qty > 0 ?
                                        <Button.Group fluid size={windowWidth < 600 ? 'small' : 'big'}>
                                            <Button
                                                loading={atcLoad}
                                                disabled={atcLoad}
                                                color='black'
                                                icon="plus cart"
                                                labelPosition='left'
                                                content="Add to Cart"
                                                onClick={() => {
                                                    if (ses.getItem('loginData')) {
                                                        if (isOrdered == 0 && isOrdered !== null) {
                                                            if (selectedColor == null) {
                                                                Toast.fire({
                                                                    icon: 'warning',
                                                                    text: 'Color cannot be empty',
                                                                    position: 'center'
                                                                })
                                                            } else if (selectedSize == null) {
                                                                Toast.fire({
                                                                    icon: 'warning',
                                                                    text: 'Size cannot be empty',
                                                                    position: 'center'
                                                                })
                                                            } else {
                                                                pickedProduct(slcProduct(selectedColor, selectedSize))
                                                                addProductCart('atc')
                                                            }
                                                        } else if (isOrdered == 1) {
                                                            Toast.fire({
                                                                icon: 'warning',
                                                                text: 'There`s pending Order',
                                                                position: 'center'
                                                            })
                                                            navigate('/cart/checkout')
                                                        } else if (isOrdered == 2) {
                                                            Toast.fire({
                                                                icon: 'warning',
                                                                text: 'There`s pending Payment',
                                                                position: 'center'
                                                            })
                                                            navigate('/cart/checkout/payment')
                                                        } else if (isOrdered == 3) {
                                                            Toast.fire({
                                                                icon: 'warning',
                                                                text: 'There`s pending Payment',
                                                                position: 'center'
                                                            })
                                                            navigate('/cart/checkout/paymentSnap')
                                                        }
                                                    } else {
                                                        Toast.fire({
                                                            icon: "warning",
                                                            text: "You have to register first, before you can shop, if you already have an account, please log in to start shopping",
                                                            position: "center"
                                                        })
                                                    }
                                                }}
                                            />
                                            <Button.Or />
                                            <Button
                                                loading={coLoad}
                                                disabled={coLoad}
                                                color='orange'
                                                content="Checkout"
                                                labelPosition='right'
                                                icon="arrow alternate circle right outline"
                                                onClick={() => {
                                                    if (ses.getItem('loginData')) {
                                                        if (isOrdered == 0 && isOrdered !== null) {
                                                            if (selectedColor == null) {
                                                                Toast.fire({
                                                                    icon: 'warning',
                                                                    text: 'Color cannot be empty',
                                                                    position: 'center'
                                                                })
                                                            } else if (selectedSize == null) {
                                                                Toast.fire({
                                                                    icon: 'warning',
                                                                    text: 'Size cannot be empty',
                                                                    position: 'center'
                                                                })
                                                            } else {
                                                                pickedProduct(slcProduct(selectedColor, selectedSize))
                                                                addProductCart('co')
                                                            }
                                                        } else if (isOrdered == 1) {
                                                            Toast.fire({
                                                                icon: 'warning',
                                                                text: 'There`s pending Order',
                                                                position: 'center'
                                                            })
                                                            navigate('/cart/checkout')
                                                        } else if (isOrdered == 2) {
                                                            Toast.fire({
                                                                icon: 'warning',
                                                                text: 'There`s pending Payment',
                                                                position: 'center'
                                                            })
                                                            navigate('/cart/checkout/payment')
                                                        } else if (isOrdered == 3) {
                                                            Toast.fire({
                                                                icon: 'warning',
                                                                text: 'There`s pending Payment',
                                                                position: 'center'
                                                            })
                                                            navigate('/cart/checkout/paymentSnap')
                                                        }
                                                    } else {
                                                        Toast.fire({
                                                            icon: "warning",
                                                            text: "You have to register first, before you can shop, if you already have an account, please log in to start shopping",
                                                            position: "center"
                                                        })
                                                    }
                                                }}
                                            />
                                        </Button.Group>
                                        :
                                        <></>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row >
            </Container >
            :
            <Container className='mt-5 mb-5 pt-5' fluid='sm' style={{ minHeight: '45vh', height: '100%' }}>
                <div className='mt-5'>
                    <Icon name='warning circle' size='large' color='orange' className='mb-2'/>
                    <div>{mssgTxt}</div>
                </div>
            </Container>
            }
        </>
    )

}

export default ProductPage;