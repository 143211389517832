import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import maintenanceImg from "../../assets/maintenance.png"
const Maintenance = () => {
  return (
    <Container>
        <Row style={{height:'100vh'}}>
            <Col className='d-flex align-items-center justify-content-center' xs={12} sm={12} md={12} lg={12} xl={10}>
                <Image src={maintenanceImg} width="85%" fluid/>
            </Col>
        </Row>
    </Container>
  )
}

export default Maintenance