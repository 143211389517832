import React, { useEffect, useState } from "react";
import ProductCard from "../../component/ProductCard";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useMyGlobal } from "../../component/GlobalData";
import { Col, Container, Row, Image } from "react-bootstrap";
import { Button, Dimmer, Divider, Label, Loader } from "semantic-ui-react";
import noDataImg from "../../assets/no_data.png";

export const DiscountedProductPage = () => {
    const { token, windowWidth } = useMyGlobal()
    const dataDsc = useLocation().state
    const [prdDsc, setPrdDsc] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [noData, setNoData] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const pagePerLoad = 24
    const [jmlProd, setJmlProd] = useState(pagePerLoad)
    const [loadMoreSpiner, setLoadMoreSpiner] = useState(false)
    const [loadMoreDisable, setLoadMoreDisable] = useState(false)
    const [labelState, setLabelState] = useState('Load More')

    const getProductDiscount = async (halaman, jumlah) => {
        setLoadMoreSpiner(true)
        setLoadMoreDisable(true)
        await axios({
            method: "GET",
            url: `https://hoopsdevteam.com/api_web/controler/product.php?index=22&halaman=${halaman}&jumlah=${jumlah}`,
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': token
            }
        })
            .then(res => {
                let data = []
                if (res.data.state === 200 && res.data.data.length > 0) {
                    data = res.data.data
                    setIsLoading(false)
                    setPrdDsc(prevNew => {
                        // Filter data baru yang belum ada di dalam newProduct
                        const filteredData = data.filter(item =>
                            !prevNew.some(prevItem => prevItem.id_product === item.id_product)
                        );
                        // Gabungkan data lama dengan data baru yang sudah difilter
                        return [...prevNew, ...filteredData]
                    })
                    setLoadMoreSpiner(false)
                    setLoadMoreDisable(false)
                } else {
                    setIsLoading(false)
                    setLoadMoreDisable(true)
                    setLoadMoreSpiner(false)
                    setLabelState(`That's the last line for dicounted product`)
                }
            })
    }

    const handleClickLoadMore = () => {
        setCurrentPage(currentPage + pagePerLoad)
        getProductDiscount(currentPage + pagePerLoad, jmlProd)
    }

    useEffect(() => {
        if (prdDsc.length == 0){
            setNoData(true)
        } else {
            setNoData(false)
        }
    }, [prdDsc])

    useEffect(() => {
        if (!dataDsc) {
            getProductDiscount(currentPage, jmlProd)
        } else {
            if (dataDsc.data.length > 0) {
                setPrdDsc(dataDsc.data)
                setIsLoading(false)
            } else {
                getProductDiscount(currentPage, jmlProd)
            }
        }
        window.scrollTo(0, 0)
    }, [])

    return (
        <Container style={{ minHeight: '65vh', marginTop: '15vh' }}>
            <h1 className="display-5">SALE</h1>
            {
                windowWidth > 600 ?
                <Divider horizontal>maybe the discount will end soon, let's checkout the items you want immediately</Divider>
                :
                <p className="text-uppercase fw-bold" style={{fontSize:'0.9em'}}>maybe the discount will end soon, let's checkout the items you want immediately</p>
            }
            <Row className="d-flex justify-content-start mb-3 mt-3">
                <Dimmer active={isLoading} inverted><Loader active={isLoading}>Loading</Loader></Dimmer>
                <div className="mt-3" style={noData ? { display: 'block' } : { display: 'none' }}>
                    <Image
                        src={noDataImg}
                        width={200}
                        height={200}
                    />
                </div>
                {
                    prdDsc.map((item, i) =>
                        <ProductCard data={item} key={i} option="newProduct" />
                    )
                }
                <Col md={12} className="d-flex justify-content-end pe-2">
                    <Button
                        content={labelState}
                        onClick={() => handleClickLoadMore()}
                        loading={loadMoreSpiner}
                        basic
                        color="orange"
                        disabled={loadMoreDisable}
                        icon={labelState == 'Load More' ? 'redo alternate' : 'ban'}
                    />
                </Col>
            </Row>
        </Container>
    )
}