import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const TermMember = () => {
    return (
        <section>
            <Container style={{marginTop:'10%', marginBottom:'10%'}}>
                <Row>
                    <Col md={12}>
                        <ul>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt eos laboriosam commodi, accusantium iusto soluta nam assumenda magnam sint perspiciatis, fugiat amet voluptate neque quasi veniam porro libero perferendis officia.</li>
                            <li>Officia itaque eum corporis at unde consequuntur? Voluptatem dolores repudiandae rem numquam iste qui mollitia libero facere deleniti omnis illum iusto obcaecati deserunt, nam tenetur. Reprehenderit vero eius quidem! Minima.</li>
                            <li>Provident velit vitae molestias dolorum nostrum voluptatem, impedit est neque placeat maiores iusto quisquam perspiciatis suscipit expedita laboriosam beatae consequuntur obcaecati quis earum vel molestiae. Deserunt quaerat molestias architecto eos?</li>
                            <li>Perferendis, soluta provident eum maiores nihil accusamus odio, repellat velit error vero recusandae magni distinctio sed delectus dolor. Minus dolor in incidunt ad quia, aliquid rem blanditiis ex quasi aperiam!</li>
                            <li>Quo impedit, laboriosam in modi architecto nobis quas accusantium, ex rem quos odit labore illum aliquam laudantium molestiae provident! Reprehenderit corporis odit sequi amet praesentium omnis? Possimus fugiat totam corporis.</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default TermMember;