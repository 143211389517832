import axios from "axios"
import React, { useState, useEffect } from "react"
import { Row, Col, Figure, Container, Carousel } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { Divider, Icon, Label, Placeholder } from "semantic-ui-react"
import { useMyGlobal } from "./GlobalData"
import { BsBookmarkStar } from "react-icons/bs";

const CategoryMain = () => {
    const navigate = useNavigate()
    const { token, categoryData, windowWidth } = useMyGlobal()
    const itemsPerCarousel = windowWidth < 600 ? 3 : 6;
    const groupedData = []

    for (let i = 0; i < categoryData.length; i += itemsPerCarousel) {
        groupedData.push(categoryData.slice(i, i + itemsPerCarousel));
    }


    
    const handleClickParent = (data) => {
        let par = data
        par.from = 'parent'
        par.selected_child = 0

        navigate(`/product_list/${data.id_category}`, { state: par })
    }


    return (
        <Container fluid className="mt-2 mb-4">
                    <Row>
                        <Col md={12} className="text-start">
                            <div className="text-center">
                                <BsBookmarkStar className="fs-2"/>
                                <Divider horizontal>Shop by brand</Divider>
                                {/* <Label content="Brand" icon="tags" className="fs-5 mb-3" basic color="black" /> */}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3"></Row>
                    <Carousel keyboard={true} variant="dark" indicators={true} touch={true} controls={false} interval={null}>
                        {
                            groupedData.map((group, g) => (
                                <Carousel.Item key={g}>
                                    <Row>
                                        {
                                            group.map((item, i) => (
                                                <Col lg={2} md={2} sm={2} xs={windowWidth < 600 ? 4 : 2} className="mb-3 pb-4" key={i}>
                                                    <Figure.Image
                                                        className="rounded product-card"
                                                        width={150}
                                                        height={150}
                                                        alt={item.category_name}
                                                        src={item.thumbnail_image}
                                                        onClick={() => handleClickParent(item)}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                    
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Carousel.Item>
                            ))
                        }
                    </Carousel>
                </Container>
    )
}

export default CategoryMain;