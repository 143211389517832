import React from "react";
import { useState } from "react";
import { Alert } from "react-bootstrap";

const Alerts = ({ variant, heading, content }) => {
    const [showAlert, setShowAlert] = useState(true);
    
    return (
        <Alert variant={variant} show={showAlert} onClose={() => setShowAlert(false)} dismissible>
            <Alert.Heading>{heading}</Alert.Heading>
            <p>{content}</p>
        </Alert>
    )
}

export default Alerts;