import React, { useEffect, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { Icon, Popup } from 'semantic-ui-react';

const FloatingObject = () => {
    const [isVisible, setIsVisible] = useState(true);
    const log = JSON.parse(localStorage.getItem('loginData'))
    // const handleToggleVisibility = () => {
    //     setIsVisible(!isVisible);
    // };

    const handleClickWA = () => {
        const phoneNumber = '62811220187'
        const customerName = log.profile.first_name + ' ' + JSON.parse(localStorage.getItem('loginData')).profile.last_name
        const customerEmail = log.profile.email
        const templateText = encodeURIComponent(`Halo Hoops Indonesia,\n saya ${customerName} (${customerEmail})\n`)
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${templateText}`
        window.open(whatsappUrl, '_blank')
    }

    useEffect(() => {
        if (log){
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    },[log])

    return (
        <div>
            {isVisible && (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        padding: '10px',
                        background: '#000',
                        color: '#fff',
                        borderRadius: '50px',
                        cursor: 'pointer',
                        zIndex: 1000,
                    }}
                    // onClick={handleToggleVisibility}
                >
                    <Popup trigger={
                    <span onClick={() => handleClickWA()}>
                        <Icon name='whatsapp' size='large' />
                        CS
                    </span>
                    }
                    content="Contact our customer services"
                    size='mini'
                    />
                </div>
            )}
        </div>
    );
};

export default FloatingObject;