import axios from "axios";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Wheel } from "react-custom-roulette";
import { Button, Label } from "semantic-ui-react";
import { useMyGlobal } from './GlobalData';
import { Toast } from "./Toast";


const Roulette = ({ data, cart, setCart }) => {
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [rouletteData, setRouletteData] = useState(data);
    const [played, setPlayed] = useState(false)
    const { token } = useMyGlobal()

    
    const handleSpinClick = () => {
        const newPrizeNumber = Math.floor(Math.random() * data.length);
        setPlayed(true)
        updateChance(cart)
        if (newPrizeNumber == 1) {
            setPrizeNumber(2)
        } else {
            setPrizeNumber(newPrizeNumber)
        }

        setMustSpin(true);
    };

    const cartProduct = async (idCustomer) => {
        const param = new URLSearchParams()
        param.append('id_customer', idCustomer)

        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/cart.php/?index=5`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token

            },
            data: param
        })
            .then((response) => {
                if (response.data.state == 200) {
                    let data = response.data.data
                    setCart(data)
                }
            })
            .catch((error) => console.log(error.response.state))
    }


    const handleClickTakePrize = () => {
        const prize = data[prizeNumber]
        if (prize.prize_tipe === 'zoonk') {
            Toast.fire({
                icon:'warning',
                text:'You are not lucky, try next time...',
                position:'top-right'
            })
        } else {
            let msg = window.confirm("Are you sure you will take this prize?")
            if (msg) { console.log(prize)
                if (prizeNumber > 0 && prize.prize_tipe === 'voucher') {
                    const param = data[prizeNumber]
                    param.id_customer = cart.customer.id_customer
                    param.id_member = cart.customer.id_member

                    axios({
                        method: "POST",
                        url: `${global.SRV}api_hoops/controler/voucher.php/?index=5`,
                        headers: {
                            'Content-Type': 'text/plain',
                            'authorization': token
                        },
                        data: param
                    })
                        .then(res => {
                            if (res.data.state === 200) {
                                Toast.fire({
                                    icon:'success',
                                    text:'The prize has been successfully add in the voucher list and is ready to be used',
                                    position:'top-right'
                                })
                            } else {
                                Toast.fire({
                                    icon:'error',
                                    text : 'Failed to prepare Prize',
                                    position:'top-right'
                                })
                            }
                        })
                } else if (prizeNumber > 0 && prize.prize_tipe === 'item') {
                    const param = new URLSearchParams()
                    const prize = data[prizeNumber]
                    console.log(cart)
                    param.append('id_cart', cart.summary.id_cart)
                    param.append('id_product', prize.id_product)
                    param.append('barcode', prize.barcode)
                    param.append('size', prize.size)

                    axios({
                        method: "POST",
                        url: `${global.SRV}api_hoops/controler/cart.php/?index=13`,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'authorization': token
                        },
                        data: param
                    })
                        .then(res => {
                            if (res.data.state === 200) {
                                cartProduct(cart.customer.id_customer)
                            }
                        })
                } else {
                    Toast.fire({
                        icon:'warning',
                        text:'No prizes yet',
                        position:'top-right'
                    })
                }
            }
        }
    }

    const updateChance = (data) => {
        const param = new URLSearchParams()
        param.append('id_cart', data.summary.id_cart)
        param.append('id_customer', data.customer.id_customer)
        param.append('option', '-1')
        param.append('is_played', cart.is_played)
        
        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/cart.php/?index=14`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: param
        })
            .then(res => {
                console.log(res.data)
            })
    }

    useEffect(() => {
        const addShortString = data.map((item) => {
            return {
                completeOption: item.name,
                option:
                    item.name.length >= 30
                        ? item.name.substring(0, 30).trimEnd() + "..."
                        : item.name,
            }
        })
        setRouletteData(addShortString);
    }, [data])

    useEffect(() => {
        setPrizeNumber(0)
        setMustSpin(false)
        cartProduct(cart.customer.id_customer)
    }, [])
    
    return (
        <>
            <Row className="mt-3 mb-5">
                <Col md={12} className="text-center mb-3">
                    {
                        played || mustSpin ?
                        <div className="fw-bold mb-3">Congratulations on getting the following prize</div>
                        : <></>
                    }
                    <Label
                        className={!mustSpin ? 'zoomin-button mb-2' : "mb-2"}
                        color="orange"
                        size="big"
                        // onClick={handleSpinClick}
                        content={played ? !mustSpin ? rouletteData[prizeNumber].completeOption : "Wait..." : "No prizes yet..."}
                    />
                    <div>Check the voucher list to use the gift in the form of a voucher <br/> Prizes will be added to the basket if the prize is in the form of goods.</div>
                </Col>
                <Col md={12} className='d-flex justify-content-center mb-3'>
                    <div >
                        <Wheel
                            mustStartSpinning={mustSpin}
                            spinDuration={[0.6]}
                            prizeNumber={prizeNumber}
                            data={rouletteData}
                            outerBorderColor={["#ccc"]}
                            outerBorderWidth={[9]}
                            innerBorderColor={["#f2f2f2"]}
                            radiusLineColor={["tranparent"]}
                            radiusLineWidth={[1]}
                            textColors={["#f5f5f5"]}
                            textDistance={55}
                            fontSize={[14]}
                            backgroundColors={[
                                "#3f297e",
                                "#175fa9",
                                "#169ed8",
                                "#239b63",
                                "#64b031",
                                "#efe61f",
                                "#f7a416",
                                "#e6471d",
                                "#dc0936",
                                "#e5177b",
                                "#be1180",
                                "#871f7f"
                            ]}
                            onStopSpinning={() => {
                                setMustSpin(false);
                                cartProduct(cart.customer.id_customer)
                            }}
                        />
                    </div>
                </Col>
                <Col md={12} className="text-center">
                    <div className="mb-2 fw-bold">Chance : {cart.roulette_chance}</div>
                    {
                        !played && !mustSpin ?
                                parseInt(cart.roulette_chance) > 0 ?
                                <Button icon='redo' className="button roulette-button" onClick={handleSpinClick} content='Putar..' size="big" color="black" />
                                :<><Label content="Your chance is over..." size="big"/></>
                            :

                            <Button.Group size="big">
                                <Button content="Ambil Hadiah" onClick={handleClickTakePrize} disabled={mustSpin} color="orange" />
                                <Button.Or />
                                {
                                    parseInt(cart.roulette_chance) > 0 ?
                                        <Button content="Putar Lagi" onClick={handleSpinClick} disabled={mustSpin} color="black" />
                                        : <Button content="No Chance..." size="big"/>
                                }
                            </Button.Group>
                    }
                </Col>
            </Row>
        </>
    )
}

export default Roulette;
