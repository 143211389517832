
export const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export function formatToK(number) {
    let result = (number / 1000);

    if (Number.isInteger(result)) {
        return result + 'K';
    } else {
        return result.toFixed(1) + 'K';
    }
}