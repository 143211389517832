import React from "react";
import { Col, Container, Row, Card, Stack, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { useMyGlobal } from "../../component/GlobalData";


const OrderExpirePage = () => {
    const data = useLocation().state
    const navigate = useNavigate()
    const {token} = useMyGlobal()

    return (
        <Container style={{ paddingTop: "12%", minHeight: "100vh" }}>
            {
                data ?
                    <section>
                        <Row className="d-flex justify-content-center mb-4">
                            <Col xl={6} lg={8} md={8} sm={12} >
                                <div className="display-5 mb-3">Order Expired...!</div>
                                <p>Your order has expired, the order has been automatically cancelled, you can order the same item as long as it is still available</p>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Col xl={6} lg={8} md={8} sm={12} className="text-start">
                                <Card className="mb-5">
                                    <Card.Header >
                                        <Row>
                                            <Col md={6} className="fw-bold">
                                                <div>#{data.summary.order_id}</div>
                                            </Col>
                                            <Col md={6} className="text-end small">
                                                <div className="text-muted">Order placed at {data.summary.date}</div>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className="mb-3">
                                            <Col>
                                                {
                                                    data.products.map((item, i) => (
                                                        <Stack direction="horizontal" gap={3} key={i}>
                                                            <Image src={item.product_image ? item.product_image.image : 'https://via.placeholder.com/500x400.png'} width={65} height={60} rounded />
                                                            <Stack direction="vertical" gap={0}>
                                                                <div className="fw-bold">{item.product_name} (x{item.qty})</div>
                                                                <div className="">
                                                                    <span className="me-3">{global.IDR.format(item.final_price)}</span>
                                                                    <span className="text-muted"><s>{global.IDR.format(item.price)}</s></span>
                                                                </div>
                                                                <div className="small">
                                                                    <span className="me-3">Size : {item.selected_size}</span>
                                                                    <span>Color : &nbsp;
                                                                        <canvas width={10} height={10} style={{ backgroundColor: item.selected_color.color_main }} key={1} />
                                                                        <canvas width={10} height={10} style={{ backgroundColor: item.selected_color.color_secondary }} key={2} />
                                                                        <canvas width={10} height={10} style={{ backgroundColor: item.selected_color.color_accent }} key={3} />
                                                                    </span>
                                                                </div>

                                                            </Stack>
                                                        </Stack>
                                                    ))
                                                }
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Row className="d-flex justify-content-center">
                                    <Col xl={6} lg={8} md={8} sm={12} className="text-center">
                                        <Button content="OK, that's fine" onClick={() => navigate("/")}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </section>
                    :
                    <section>
                        <div className="display-1">404</div>
                        <div className="text-muted">Page not found, I think you're lost</div>
                    </section>
            }
        </Container>
    )
}

export default OrderExpirePage