import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row, Container, Col, Stack } from "react-bootstrap";
import { Divider, Label } from "semantic-ui-react";
import ProductCard from "./ProductCard";
import { useMyGlobal } from './GlobalData';
import { FaBoxOpen } from "react-icons/fa";

const NewProduct = ({ data, fsData }) => {
    const {wishData} = useMyGlobal()
  
    return (
        <Container fluid className="mt-4 mb-4">
            <React.Fragment>
                <Row>
                    <Col md={12} className="text-start">
                        <div className="text-center fs-2"><FaBoxOpen/></div>
                        <Divider horizontal>New Arrival</Divider>
                        {/* <Label content="New Arrival" icon="box" className="fs-5 mb-3" basic color="black"/> */}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row className="mt-2 justify-content-start">
                            {
                                (data.length > 0) ? (
                                    data.map((item, i) => {
                                        const isWish = wishData.some(wishItem => wishItem.id_product === item.id_product);
                                        return (
                                            <ProductCard
                                                data={item} key={i}
                                                option="newProduct"
                                                fsData={fsData}
                                                isWish={isWish}
                                            />
                                        )
                                    })
                                )
                                    :
                                    <>nothing</>
                            }
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        </Container>
    )
}

export default NewProduct