import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Stack, Modal, Table, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Icon, Label, List } from "semantic-ui-react";
import bcaLogo from "../../assets/bca.png";
import { useMyGlobal } from "../../component/GlobalData";
import TimerCountDown from "../../component/TimerCountDown";

const Payment = ({ cart, setCart, updateNotif }) => {
    // const api_res = useLocation().state
    const navigate = useNavigate()
    const [apiRes, setApiRes] = useState(null)
    const dataApi = useLocation().state
    const expiry_date = new Date((apiRes) ? apiRes.expiry_time : null)
    const localOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    const expiryFormated = expiry_date.toLocaleDateString('en-US', localOptions)
    const [orderDate, setOrderDate] = useState('')
    const [copyBtn, setCopyBtn] = useState('Copy')
    const [currentTime, setCurrentTime] = useState(null)
    const [courierDetail, setCourierDetail] = useState(null)
    const [serviceDetail, setServiceDetail] = useState(null)
    // const [cart, setCart] = useState(null)
    const idCustomer = (localStorage.getItem('loginData')) ? JSON.parse(localStorage.getItem('loginData')).id : null
    const [detailShow, setDetailShow] = useState(false)
    const {token} = useMyGlobal()
    const [perDetik, setPerDetik] = useState(1)

    const detailHide = () => (setDetailShow(false))

    const stockUpdate = (prod) => {
        if (prod) {
            const param = prod
            axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/order.php/?index=15`,
                headers: {
                    'Content-Type': 'text/plain',
                    'authorization' : token
                },
                data: param
            })
                .then(res => {
                    
                })
        }
    }

    const handleCancel = (status, opt) => {
        const param = new URLSearchParams()
        const idC = cart.summary.id_cart
        const idO = cart.summary.order_id
        const sCode = () => {
            if (status == 'EXPIRE'){
                return "5"
            }else if (status == "PAYMENT CANCELED"){
                return "4"
            }else {
                return "0"
            }
        }

        param.append('id_customer', idCustomer)
        param.append('id_cart', idC)
        param.append('status', status)
        param.append('id_order', idO)
        param.append('option', opt)
        param.append('status_code', sCode)

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/payment.php/?index=3`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: param
        })
            .then(response => {

                if (opt === 'EXP') {
                    cart.is_ordered = 0
                    cart.products = []
                    stockUpdate(cart.products) //STOCK BALIK KETIKA EXPIRE
                    navigate('/')
                }

                if (response.data.status_code == 200) {

                    stockUpdate(cart.products) // STOCK BALIK WHILE CANCEL

                    const updateCart = async (idCustomer) => {
                        const param = new URLSearchParams()
                        param.append('id_customer', idCustomer)

                        await axios({
                            method: "POST",
                            url: `${global.SRV}api_hoops/controler/cart.php/?index=5`,
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'authorization': token
                            },
                            data: param
                        })
                            .then((response) => {
                                if (response.data.state == 200) {
                                    setCart(response.data.data)
                                }
                            })
                            .catch((error) => console.log(error.response.status))
                    }

                    updateCart(idCustomer)
                    updateNotif(idCustomer)

                    alert(response.data.status_message)
                    navigate('/')
                }
            })
    }

    const param = new URLSearchParams()
    param.append('id_customer', idCustomer)

    const getServiceDetail = async (idCourier, idService) => {
        const param = new URLSearchParams()
        param.append('id_courier', idCourier)
        param.append('id_service', idService)

        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/courier.php/?index=8`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    setServiceDetail(res.data.data)
                }
            })
    }

    const getCourierDetail = async (id) => {
        const param = new URLSearchParams()
        param.append('id_courier', id)
        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/courier.php/?index=7`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    setCourierDetail(res.data.data)
                }
            })
    }

    const getCart = () => {
        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/cart.php/?index=5`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: param
        })
            .then(response => { 
                if (response.data.state == 200) {
                    if (response.data.data !== cart){
                    setCart(response.data.data)
                    payment(response.data.data.summary.order_id, response.data.data.summary.id_cart, response.data.data.customer.id_customer)
                    setOrderDate(new Date(response.data.data.summary.date).toLocaleDateString('en-US', localOptions))
                    getCourierDetail(response.data.data.summary.kurir)
                    getServiceDetail(response.data.data.summary.service, response.data.data.summary.kurir)
                    }
                }
            })
    }

    const clearCart = (idCart, idCust, oId) => {
        const param = new URLSearchParams()

        param.append('id_customer', idCust)
        param.append('id_cart', idCart)

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/cart.php/?index=11`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: param
        })
            .then(response => {
                if (response.data.state == 200) {
                    navigate(`/cart/checkout/payment_success?id_order=${oId}`, { state: { id_order: oId } })
                }
            })
    }


    const payment = async (oId, cId, custId) => {
        const param = new URLSearchParams()
        param.append('id_order', oId)
        param.append('id_cart', cId)

        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/payment.php/?index=2`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: param
        })
            .then(response => {
                console.log(response.data)
                let status = response.data.data.payment_status
                if (response.data.state == 200 && response.data.data_count > 0) {
                    if (status == "settlement") {
                        clearCart(cId, custId, oId)
                        getCart()
                    } else if (status == "pending") {
                        setApiRes(response.data.data)
                    } else if (status == "expire") {
                        setApiRes(response.data.data)
                    }
                } else {
                    navigate(`/cart/checkout/payment_success?id_order=${oId}`, { state: { id_order: oId } })
                }
            })
    }


    useEffect(() => {
        // setPerDetik(perDetik + 1)
        // if (perDetik === 5){
        //     setPerDetik(1)
        //     getCart()

        // }
        getCart()
    }, [])

    // useEffect(() => {
    //     getCart()
    // },[])


    // useEffect(() => { //CLEAR CART ON EXPIRED
    //     const param = new URLSearchParams()
    //     const idC = cart && cart.summary.id_cart
    //     const idO = cart && cart.summary.order_id

    //     param.append('id_customer', idCustomer)
    //     param.append('id_cart', idC)
    //     param.append('status', 'EXPIRE')
    //     param.append('id_order', idO)
    //     // param.append('option', 'EXP')

    //     const updateCartOrder = () => {
    //         axios({
    //             method: "POST",
    //             url: 'http://localhost/api_hoops/controler/payment.php/?index=3',
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded',
    //             },
    //             data: param
    //         })
    //             .then(response => {
    //                 console.log(response.data)
    //             })
    //     }

    //     if (currentTime !== null && currentTime <= 0) {
    //         updateCartOrder()
    //     }
    // }, [currentTime])

    return (
        (apiRes) &&
        <section style={{ marginTop: "10rem", minHeight: "100vh", height: "100%" }}>
            <Container>
                <Modal show={detailShow} onHide={detailHide} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Order Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-4 mt-2">
                            <Col md={12} className="mb-3">
                                <Stack direction="horizontal" gap={3}>
                                    <div style={{ width: '70px' }}>Order</div>:<div className="fw-bold">{cart.summary.order_id}</div>
                                </Stack>
                                <Stack direction="horizontal" gap={3}>
                                    <div style={{ width: '70px' }}>Date</div>:<div className="fw-bold">{orderDate}</div>
                                </Stack>
                            </Col>
                            <Col md={12}>
                                <Table className="align-middle">
                                    <thead className="bg-light">
                                        <tr>
                                            <td>#</td>
                                            <td>Product</td>
                                            <td>Price</td>
                                            <td>Qty</td>
                                            <td>Total</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cart.products.map((item, i) => (
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td style={{ fontSize: 12 }}>
                                                        <Stack direction="horizontal" gap={3}>
                                                            <Image src={item.product_image ? item.product_image.image : 'https://via.placeholder.com/500x400.png'} width={60} height={50} rounded />
                                                            <Stack direction="vertical" gap={0}>
                                                                <div>{item.product_id}</div>
                                                                <div className="fw-bold">{item.product_name}</div>
                                                                <Stack direction="horizontal" gap={3}>
                                                                    <div>Size : {item.selected_size}</div>
                                                                    <div>Color :</div>
                                                                    <Stack direction="horizontal" gap={0}>
                                                                        <canvas style={{ backgroundColor: item.selected_color.color_main }} height={10} width={10} />
                                                                        <canvas style={{ backgroundColor: item.selected_color.color_secondary }} height={10} width={10} />
                                                                        <canvas style={{ backgroundColor: item.selected_color.color_accent }} height={10} width={10} />
                                                                    </Stack>
                                                                </Stack>
                                                            </Stack>
                                                        </Stack>
                                                    </td>
                                                    <td>
                                                        <div>{global.IDR.format(item.final_price)}</div>
                                                        <small className="text-muted"><s>{global.IDR.format(item.price)}</s></small>
                                                    </td>
                                                    <td>{item.qty}</td>
                                                    <td>{global.IDR.format(item.final_price)}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={3}>Total Item</td>
                                            <td>{cart.summary.totalItem}</td>
                                            <td>{global.IDR.format(cart.summary.totalPrice)}</td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Col>
                            <Col md={12} className="d-flex justify-content-end mb-4">
                                <div>
                                    <Stack direction="horizontal"><div style={{ width: 155 }}>Shipping voucher</div><div style={{ width: 138 }}>{global.IDR.format(cart.summary.voucher_ongkir)}</div></Stack>
                                    <Stack direction="horizontal"><div style={{ width: 155 }}>Shipping cost</div><div style={{ width: 138 }}>{global.IDR.format(cart.summary.service_cost)}</div></Stack>
                                    <Stack direction="horizontal"><div style={{ width: 155 }}>Voucher</div><div style={{ width: 138 }}>{global.IDR.format(cart.summary.voucher)}</div></Stack>
                                    <Stack direction="horizontal" className="fw-bold mt-1"><div style={{ width: 155 }} >Grand total</div><div style={{ width: 138 }}>{global.IDR.format(cart.summary.total_order)}</div></Stack>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="fw-bold mb-2" style={{ fontSize: 16 }}>Shipping Address</div>
                                <Stack direction="vertical" gap={1}>
                                    <div><Label size="small">{cart.customer.address.address_label}</Label></div>
                                    <div className="fw-bold">{cart.customer.address.recipient_name} ({cart.customer.address.celular_number})</div>
                                    <div>{cart.customer.address.address_detail}</div>
                                    <div>{cart.customer.address.destination} {cart.customer.address.zip_code}</div>
                                </Stack>
                            </Col>
                            <Col md={6}>
                                <div className="fw-bold mb-2" style={{ fontSize: 16 }}>Courier detail</div>
                                {(courierDetail && serviceDetail) ? // KADANG MUNCUL KADANG NULL SERVICE DETAIL
                                    <Stack direction="horizontal" gap={3}>
                                        <Image src={courierDetail.logo} width={60} height={50} />
                                        <Stack direction="vertical" >
                                            <div className="fw-bold">{courierDetail.name}</div>
                                            <div><span className="me-2">{serviceDetail.service_display}</span> <span>{global.IDR.format(cart.summary.service_cost)}</span></div>
                                            <div>{serviceDetail.estimasi}</div>
                                        </Stack>

                                    </Stack>
                                    : <>Loading ...</>
                                }
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Row className="mb-5">
                    <Col>
                        <div className="fs-4 mb-4">Finish Payment On</div>
                        <div className="fs-3 fw-bold mb-4"><TimerCountDown expiryTime={expiry_date} setCurentTime={setCurrentTime} sendTime={true}/></div>
                        <div className="fs-5 text-muted mb-2">Pay before the payment deadline</div>
                        <div className="fs-5 fw-bold">{expiryFormated}</div>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col>
                        <div className="mb-4 fs-2 fw-bold text-danger">{currentTime <= 0 && currentTime != null ? 'EXPIRED' : <></>}</div>
                        {currentTime <= 0 && currentTime != null ?
                            <Button content="Okay.. Cancel This Order" onClick={() => handleCancel('EXPIRE', 'EXP')} /> :
                            <Button content="Cancel Order" onClick={() => handleCancel('PAYMENT CANCELED', 'CL')} />
                        }
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center mb-5">
                    <Col xl={6} lg={8} md={10} sm={12}>
                        <Card>
                            <Card.Header className="text-start fw-bold">
                                <Stack direction="horizontal">
                                    <div>BCA Virtual Account</div>
                                    <div className="ms-auto"><img src={bcaLogo} width={46} height={16} /></div>
                                </Stack>
                            </Card.Header>
                            <Card.Body>
                                <Row className="mb-4">
                                    <Col md={6} className="d-flex justify-content-start">
                                        <Stack direction="vertical">
                                            <div className="text-start text-muted mb-2">Virtual Account Number</div>
                                            <div className="text-start fs-5 fw-bold">{apiRes && apiRes.va_number}</div>
                                        </Stack>
                                    </Col>
                                    <Col md={6} className="d-flex justify-content-end">
                                        <div
                                            className="d-flex align-items-center fs-6 fw-bold"
                                            onClick={() => {
                                                navigator.clipboard.writeText(apiRes && apiRes.va_number)
                                                setCopyBtn('Copied')
                                                setTimeout(() => { setCopyBtn('Copy') }, 1000)
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <span className="me-2">{copyBtn}</span>
                                            <span><Icon name="copy outline" /></span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Stack direction="vertical">
                                            <div className="text-start text-muted mb-2">Total Payment</div>
                                            <div className="text-start fs-5 fw-bold">{apiRes && global.IDR.format(apiRes.total_payment)}</div>
                                        </Stack>
                                    </Col>
                                    <Col md={6} className="d-flex justify-content-end">
                                        <div
                                            className="d-flex align-items-center fs-6 fw-bold"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <span onClick={() => setDetailShow(true)} style={{ cursor: 'pointer' }}>View Detail</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center" >
                    <Col xl={6} lg={8} md={10} sm={12}>
                        <div className="fs-5 text-start fw-bold">How to payment</div>
                        <List as='ol' className="text-start">
                            <List.Item as='li'>Insert your ATM / Login card into your BCA account</List.Item>
                            <List.Item as='li'>Enter the PIN</List.Item>
                            <List.Item as='li'>Select transfer</List.Item>
                            <List.Item as='li'>Select 'BCA Virtual Account'</List.Item>
                            {/* <List.Item as='li'>Enter your virtual account number {api_res.va_numbers[0].va_number} followed by your reference number</List.Item> */}
                            <List.Item as='li'>Make a payment</List.Item>
                            <List.Item as='li'>Done</List.Item>
                        </List>
                    </Col>
                </Row>
            </Container>
        </section>

    )
}

export default Payment