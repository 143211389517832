import axios from 'axios';
import React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import Alerts from '../../component/Alerts';
import { useMyGlobal } from '../../component/GlobalData';
import { Toast } from '../../component/Toast';

const VerificationEmail = () => {
    const { state } = useLocation()
    const [veriCode, setVeriCode] = useState()
    const [verified, setVerified] = useState(false)
    const [btnSendState, setBtnSendState] = useState(false)
    const {token} = useMyGlobal()
    const [btnSubDsb, setBtnSubDsb] = useState(false)

    const validateVeriEmail = async (e) => {
        e.preventDefault()
        const param = new URLSearchParams()
        setBtnSubDsb(true)
        param.append('email', state.data.email)
        param.append('veriCode', veriCode)

        if (veriCode !== '') {
            await axios({
                method: 'POST',
                url: `${global.SRV}api_hoops/controler/signup.php/?index=3`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': token
                },
                data: param
            })
                .then((response) => {
                    setBtnSubDsb(false) 
                    if (response.data.state === 200) {
                        setVerified(true)
                    } else {
                        Toast.fire({
                            icon :"error",
                            title: "Invalid verification code, please check latest verification email.",
                            text : "Maybe you've asked for another code but it hasn't been sent to your email please wait for a minute, if you're still having problems, report it to our CS",
                            position:"center"
                        })
                    }
                })
        }
    }

    const ButtonSend = ({state}) => {
        if (state === true){
            return (<Button size='small' content='Resend' icon="paper plane" labelPosition='right' onClick={() => resendEmail()}/>)
        }
        if (state === false){
            return (<Button size='small' loading>Sending email..</Button>)
        }
    }

    const resendEmail = () => { 
        const param = new URLSearchParams()

        param.append('email', state.data.email)
        param.append('firstName', state.data.firstname)
        param.append('lastName', state.data.lastname)
        param.append('page', '../email/emailVerification.html')
        setBtnSendState(false)

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/signup.php/?index=2`,
            // timeout : 5000,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authorization': token
            },
            data: param
        })
            .then((response) => {
                setTimeout (() => {
                    setBtnSendState(true)
                }, 10000)   
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(()=>{
        setTimeout (() => {
            setBtnSendState(true)
        }, 10000)
    },[])
   
    return (
        <section>
            <Container className='' style={{ height: '65vh', paddingTop: '20vh' }}>
                
                {
                    (state !== null) ? //JIKA DATA YANG DIKIRIM TIDAK KOSONG
                        (verified) ?
                            // JIKA EMAIL SUDAH TERVERIFIKASI
                            (state.hasOwnProperty('g_acc')) ?
                            <section>
                                <Row className='justify-content-md-center justify-content-lg-center'>
                                    <Col sm={12} md={8}>
                                        <div className="alert alert-secondary" role="alert">
                                            <h4 className="alert-heading">Well done! Your Gmail account has been registered</h4>
                                            <div className="text-start">
                                            <p>Congratulations, your email has been successfully verified, then you can log in using the <i>Gmail Account</i> you registered.</p>
                                            <p>Now you can login using your gmail account, click the login button in the upper right corner</p>
                                            <hr />
                                            <p className="mb-0">Keep your credentials safe, don't give them to anyone and happy shopping.</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </section>
                            :
                            <section>
                                <Row className='justify-content-md-center justify-content-lg-center'>
                                    <Col sm={12} md={8}>
                                        <div className="alert alert-secondary" role="alert">
                                            <h4 className="alert-heading">Well done!</h4>
                                            <p>Congratulations, your email has been successfully verified, then you can log in using the email you registered and the password that you applied to login, if needed you can also change your password with a new password.</p>
                                            <hr />
                                            <p className="mb-0">Keep your credentials safe, don't give them to anyone and happy shopping.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </section>
                            :
                            // JIKA EMAIL BELUM DI VERIFIKASI
                            <section>
                                <Row>
                                    <Col>
                                        {(state.msg) ? <Alerts variant="secondary" heading="Warning" content={state.msg} /> : ''}
                                        <span className='fs-5'>Verification code has been sent to email : {state.data.email}</span> &nbsp;
                                        <ButtonSend state={btnSendState}/>
                                    </Col>
                                </Row>
                                <Form onSubmit={(e) => validateVeriEmail(e)}>
                                    <Row className='d-flex justify-content-center mt-4 mb-3'>
                                        <Col sm={12} md={4} lg={3}>
                                            <Form.Group>
                                                <Form.Label>Enter Verification Code</Form.Label>
                                                <Form.Control type="text" className="text-center" size='lg' autoFocus maxLength={6} minLength={6} onChange={(e) => setVeriCode(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='d-flex justify-content-center mb-5'>
                                        <Col>
                                            <Form.Group>
                                                <Button disabled={btnSubDsb} loading={btnSubDsb} content="Submit" size='large' color='black' icon="arrow alternate circle right" labelPosition='right'/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </section>
                        :
                        //JIKA DATA YANG DIKIRIM KOSONG
                        <section>
                            <Row>
                                <div className="alert alert-secondary" role="alert">
                                    You won't find anything here, please come back..!
                                </div>
                            </Row>
                        </section>
                }
            </Container>
        </section>
    )
}

export default VerificationEmail;